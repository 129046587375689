import React from 'react';
import TablePaginationMui from '@mui/material/TablePagination';

const TablePagination = ({
  count,
  labelDisplayedRows,
  labelRowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}: IProps) => {
  return (
    <TablePaginationMui
      component="div"
      count={count}
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={labelRowsPerPage}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

interface IProps {
  count: number,
  labelDisplayedRows?: ({ count, from, to }: any) => string,
  labelRowsPerPage: React.ReactNode,
  onPageChange: (event: React.MouseEvent | null, page: number) => void,
  onRowsPerPageChange: (event: any) => void,
  page: number,
  rowsPerPage: number,
  rowsPerPageOptions: Array<number | { label: string; value: number }>,
}
export default TablePagination;
