export const EUR = 'EUR';
export const PLN = 'PLN';
export const UAH = 'UAH';
export const USD = 'USD';

const orderedCurrencies = [
  EUR,
  PLN,
  UAH,
  USD,
];

export const currenciesWithoutUah = [
  EUR,
  PLN,
  USD,
];

export default orderedCurrencies;
