import {
  ERROR_DOWNLOAD_REPORT,
  ERROR_EXPORT_REPORT,
  ERROR_RECEIVE_REPORTS,
  RECEIVE_REPORTS,
  REQUEST_DOWNLOAD_REPORT,
  REQUEST_EXPORT_REPORT,
  REQUEST_REPORTS,
  SUCCESS_DOWNLOAD_REPORT,
  SUCCESS_EXPORT_REPORT,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';
import { extractFileName } from 'utils/headers';
import fileSaver from 'file-saver';

const requestReports = () => ({
  type: REQUEST_REPORTS,
});

const receiveReports = (response: any) => ({
  payload: response,
  type: RECEIVE_REPORTS,
});

const errorReceiveReports = () => ({
  type: ERROR_RECEIVE_REPORTS,
});

const getReports = () => {
  const {
    BASE_URL,
    REPORTS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${REPORTS_SERVICE}`)
    .then(({ data }) => data);
};

const fetchReports = () => (dispatch: any) => {
  dispatch(requestReports());
  return getReports()
    .then(reports => dispatch(receiveReports(reports)))
    .catch(() => dispatch(errorReceiveReports()));
};

const requestDownload = () => ({
  type: REQUEST_DOWNLOAD_REPORT,
});

const successDownload = () => ({
  type: SUCCESS_DOWNLOAD_REPORT,
});

const errorDownload = (error: any) => ({
  payload: error,
  type: ERROR_DOWNLOAD_REPORT,
});

const downloadReport = ({
  id,
  month,
  quarter,
  year,
}: any) => {
  const {
    BASE_URL,
    REPORTS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${REPORTS_SERVICE}/${id}`,
      {
        month,
        quarter,
        year,
      },
      {
        responseType: 'blob',
      })
    .then(response => ({
      report: response.data,
      title: extractFileName(response.headers['content-disposition'])
        || 'report.xlsx',
    }))
    .catch((error) => error.response?.data?.text()
      .then((text: any) => Promise.reject(JSON.parse(text))));
};

const fetchDownloadReport = ({
  id,
  month,
  quarter,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestDownload());
  return downloadReport({
    id,
    month,
    quarter,
    year,
  })
    .then(({ report, title }) => {
      fileSaver.saveAs(report, title);
      return dispatch(successDownload());
    })
    .catch((error) => dispatch(errorDownload(error)));
};

const requestExport = () => ({
  type: REQUEST_EXPORT_REPORT,
});

const exportReport = ({
  id,
  month,
  quarter,
  year,
}: any) => {
  const {
    BASE_URL,
    REPORTS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${REPORTS_SERVICE}/${id}/export`,
      { month, quarter, year }
    )
    .then(response => response.data);
};

const successExport = (response: any) => ({
  payload: response,
  type: SUCCESS_EXPORT_REPORT,
});

const errorExport = (message: any) => ({
  payload: message,
  type: ERROR_EXPORT_REPORT,
});

const fetchExportReport = ({
  id,
  month,
  quarter,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestExport());
  return exportReport({ id, month, quarter, year })
    .then((response) => {
      dispatch(successExport(response));
    })
    .catch((message) => {
      dispatch(errorExport(message));
    });
};

const exportFunctions = {
  fetchDownloadReport,
  fetchExportReport,
  fetchReports,
};

export default exportFunctions;
