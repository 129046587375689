import {
  ERROR_RECEIVE_WORKSPACES,
  RECEIVE_WORKSPACES,
  REQUEST_WORKSPACES,
  RESET_REDUCERS_DATA,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  isFailed: false,
  isFetching: false,
  isFinished: false,
  list: [],
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_WORKSPACES: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
        isFinished: false,
      };
    }

    case RECEIVE_WORKSPACES: {
      const types = action.payload;

      const list = types
        .map((type: any) => ({
          id: type.id,
          name: type.name,
        }));

      return {
        ...state,
        isFetching: false,
        isFinished: true,
        list,
      };
    }

    case ERROR_RECEIVE_WORKSPACES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
        isFinished: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
