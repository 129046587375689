import { makeStyles } from 'tss-react/mui';
import React from 'react';
import InputAdornmentMui from '@mui/material/InputAdornment';
import TextFieldMui from '@mui/material/TextField';
import useTheme from 'hooks/useTheme';

type TTextVariants = 'capitalized'
  | 'caption'
  | 'default'
  | 'subtitle'
  | 'title';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  capitalized: theme.typography.variants.capitalized,
  caption: theme.typography.variants.caption,
  default: theme.typography.variants.default,
  input: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  subtitle: theme.typography.variants.subtitle,
  title: theme.typography.variants.title,
}));

const positionOptions = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  TOP: 'top',
};

const adornmentPositionToFlexVariant = {
  [positionOptions.BOTTOM]: 'flex-end',
  [positionOptions.CENTER]: 'center',
  [positionOptions.TOP]: 'flex-start',
};

const TextField = ({
  AdornmentStart,
  AdornmentEnd,
  adornmentPosition = 'center',
  autoFocus = false,
  color = 'primary',
  disabled = false,
  fullWidth = false,
  helperText,
  inputRef,
  inputType = 'text',
  isError = false,
  label,
  maxLength,
  minRows,
  multiline = false,
  onBlur,
  onChange,
  onSelect,
  placeholder,
  size,
  textVariant = 'default',
  value,
  variant = 'outlined',
}: IProps) => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);

  return (
    <TextFieldMui
      autoFocus={autoFocus}
      color={color}
      className={classes.input}
      disabled={disabled}
      error={isError}
      fullWidth={fullWidth}
      helperText={helperText}
      InputProps={{
        classes: {
          input: classes[textVariant],
        },
        endAdornment: AdornmentEnd && (
          <InputAdornmentMui position="end">
            {AdornmentEnd}
          </InputAdornmentMui>
        ),
        startAdornment: AdornmentStart && (
          <InputAdornmentMui position="start">
            {AdornmentStart}
          </InputAdornmentMui>
        ),
        sx: { alignItems: adornmentPositionToFlexVariant[adornmentPosition] },
      }}
      inputProps={{
        maxLength,
      }}
      inputRef={inputRef}
      label={label}
      minRows={minRows}
      multiline={multiline}
      onBlur={onBlur}
      onChange={onChange}
      onSelect={onSelect}
      placeholder={placeholder}
      size={size}
      type={inputType}
      value={value}
      variant={variant}
    />
  );
};

interface IProps {
  AdornmentEnd?: any;
  AdornmentStart?: any;
  adornmentPosition?: 'top' | 'center' | 'bottom',
  autoFocus?: boolean,
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  disabled?: boolean,
  fullWidth?: boolean,
  helperText?: React.ReactNode;
  inputRef?: React.RefObject<HTMLTextAreaElement | HTMLInputElement>,
  inputType?: React.HTMLInputTypeAttribute,
  isError?: boolean;
  label?: React.ReactNode;
  maxLength?: number,
  minRows?: number | string;
  multiline?: boolean,
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onSelect?: (event: any) => void;
  placeholder?: string;
  size?: 'medium' | 'small';
  textVariant?: TTextVariants,
  value?: any;
  variant?: 'filled' | 'outlined' | 'standard';
}
export default TextField;
