import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import AuthoritiesProvider from 'components/AuthoritiesProvider';
import CustomThemeProvider from 'components/CustomThemeProvider';
import Header from 'components/Header';
import IntlProvider from 'components/IntlProvider';
import Loading from 'components/Loading';
import MissedPage from 'components/MissedPage';
import PageClientsAndProjects from 'pageProviders/ClientsAndProjects';
import PageCommonSettings from 'pageProviders/CommonSettings';
import PageCompensationTypes from 'pageProviders/CompensationTypes';
import PageCostItems from 'pageProviders/CostItems';
import PageContainer from 'components/PageContainer';
import PageContractor from 'pageProviders/Contractor';
import PageContractors from 'pageProviders/Contractors';
import PageContractTypes from 'pageProviders/ContractTypes';
import PageCurrencyRates from 'pageProviders/CurrencyRates';
import PageDefault from 'pageProviders/Default';
import PageExpenses from 'pageProviders/Expenses';
import PageInvoices from 'pageProviders/Invoices';
import PageLogin from 'pageProviders/Login';
import PagePayrolls from 'pageProviders/Payrolls';
import PageProjectMembersAndRates from 'pageProviders/ProjectMembersAndRates';
import PageReports from 'pageProviders/Reports';
import PageSettings from 'pageProviders/Settings';
import PageTaxes from 'pageProviders/Taxes';
import PageUserManagement from 'pageProviders/UserManagement';
import SearchParamsConfigurator from 'components/SearchParamsConfigurator';
import SideBar from 'components/SideBar';
import UserProvider from 'components/UserProvider';

import * as pages from 'constants/pages';
import pageURLs from 'constants/pagesURLs';
import useIsMobile from 'hooks/useIsMobile';
import userActions from '../actions/user';
import TaxTypes from '../../pageProviders/TaxTypes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  sideBarContainer: {
    position: 'absolute',
  },
}));

function App() {
  const { classes } = getClasses({});
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();
  const [state, setState] = useState({
    componentDidMount: false,
  });
  const {
    isFetchingUser,
  } = useSelector(({ user }: any) => user);

  useEffect(() => {
    dispatch(userActions.fetchUser());
    setState({
      ...state,
      componentDidMount: true,
    });
  }, []);

  return (
    <UserProvider>
      <AuthoritiesProvider>
        <CustomThemeProvider>
          <BrowserRouter>
            <SearchParamsConfigurator />
            {/* This is needed to let first render passed for App's
            * configuration process will be finished (e.g. locationQuery
            * initializing) */}
            {state.componentDidMount && (
              <IntlProvider>
                <Header onLogout={() => dispatch(userActions.fetchSignOut())} />
                {!isMobile && (
                  <div className={classes.sideBarContainer}>
                    <SideBar />
                  </div>
                )}
                {isFetchingUser && (
                  <PageContainer sideBarStub={false}>
                    <Loading />
                  </PageContainer>
                )}
                {!isFetchingUser && (
                  <Routes>
                    <Route
                      element={<PageClientsAndProjects />}
                      path={`${pageURLs[pages.clientsAndProjects]}`}
                    />
                    <Route
                      element={<PageCommonSettings />}
                      path={`${pageURLs[pages.commonSettings]}`}
                    />
                    <Route
                      element={<PageCompensationTypes />}
                      path={`${pageURLs[pages.compensationTypes]}`}
                    />
                    <Route
                      element={<PageCostItems />}
                      path={`${pageURLs[pages.costItems]}`}
                    />
                    <Route
                      element={<TaxTypes />}
                      path={`${pageURLs[pages.taxTypes]}`}
                    />
                    <Route
                      element={<PageTaxes />}
                      path={`${pageURLs[pages.taxes]}`}
                    />
                    <Route
                      element={<PageContractor />}
                      path={`${pageURLs[pages
                        .contractors]}/details/:contractorId/`}
                    />
                    <Route
                      element={<PageContractor />}
                      path={`${pageURLs[pages
                        .contractors]}/details/`}
                    />
                    <Route
                      element={<PageContractors />}
                      path={`${pageURLs[pages.contractors]}`}
                    />
                    <Route
                      element={<PageContractTypes />}
                      path={`${pageURLs[pages.contractTypes]}`}
                    />
                    <Route
                      element={<PageCurrencyRates />}
                      path={`${pageURLs[pages.currencyRates]}`}
                    />
                    <Route
                      element={<PageDefault />}
                      path={`${pageURLs[pages.defaultPage]}`}
                    />
                    <Route
                      element={<PageExpenses />}
                      path={`${pageURLs[pages.expenses]}`}
                    />
                    <Route
                      element={<PageInvoices />}
                      path={`${pageURLs[pages.invoices]}`}
                    />
                    <Route
                      element={(
                        <PageLogin
                          onSignIn={({ code }: any) => {
                            dispatch(userActions.fetchSignIn({ code }));
                          }}
                        />
                      )}
                      path={`${pageURLs[pages.login]}`}
                    />
                    <Route
                      element={<PagePayrolls />}
                      path={`${pageURLs[pages.payrolls]}`}
                    />
                    <Route
                      element={<PageProjectMembersAndRates />}
                      path={`${pageURLs[pages.clientsAndProjects]}/:projectId/`}
                    />
                    <Route
                      element={<PageReports />}
                      path={`${pageURLs[pages.reports]}`}
                    />
                    <Route
                      element={<PageSettings />}
                      path={`${pageURLs[pages.settings]}`}
                    />
                    <Route
                      element={(
                        <PageUserManagement
                          onChangeAuthorities={(newAuthorities: any) =>
                            dispatch(userActions
                              .changeAuthorities(newAuthorities))}
                        />
                      )}
                      path={`${pageURLs[pages.userManagement]}`}
                    />
                    <Route
                      element={(
                        <MissedPage
                          redirectPage={`${pageURLs[pages.defaultPage]}`}
                        />
                      )}
                      path="*"
                    />
                  </Routes>
                )}
              </IntlProvider>
            )}
          </BrowserRouter>
        </CustomThemeProvider>
      </AuthoritiesProvider>
    </UserProvider>
  );
}

export default App;
