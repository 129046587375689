export const REQUEST_REFRESH_CONTRACTORS = 'REQUEST_REFRESH_CONTRACTORS';
export const SUCCESS_REFRESH_CONTRACTORS = 'SUCCESS_REFRESH_CONTRACTORS';
export const ERROR_REFRESH_CONTRACTORS = 'ERROR_REFRESH_CONTRACTORS';

export const REQUEST_CONTRACTORS = 'REQUEST_CONTRACTORS';
export const RECEIVE_CONTRACTORS = 'RECEIVE_CONTRACTORS';
export const ERROR_RECEIVE_CONTRACTORS = 'ERROR_RECEIVE_CONTRACTORS';

export const REQUEST_CONTRACT_TYPES = 'REQUEST_CONTRACT_TYPES';
export const RECEIVE_CONTRACT_TYPES = 'RECEIVE_CONTRACT_TYPES';
export const ERROR_RECEIVE_CONTRACT_TYPES = 'ERROR_RECEIVE_CONTRACT_TYPES';

export const REQUEST_WORKSPACES = 'REQUEST_WORKSPACES';
export const RECEIVE_WORKSPACES = 'RECEIVE_WORKSPACES';
export const ERROR_RECEIVE_WORKSPACES = 'ERROR_RECEIVE_WORKSPACES';

export const RESET_REDUCERS_DATA = 'RESET_REDUCERS_DATA';
