export const UAH_IE = 'UAH_IE';
export const UAH_NP = 'UAH_NP';
export const USD_EUR = 'USD_EUR';

const LIST = [
  UAH_IE,
  UAH_NP,
  USD_EUR,
];

export default LIST;
