import {
  ERROR_CREATE_CONTRACTOR,
  ERROR_RECEIVE_CONTRACTOR,
  ERROR_UPDATE_CONTRACTOR,
  RECEIVE_CONTRACTOR,
  REQUEST_CONTRACTOR,
  REQUEST_CREATE_CONTRACTOR,
  REQUEST_UPDATE_CONTRACTOR,
  RESET_REDUCERS_DATA,
  SUCCESS_CREATE_CONTRACTOR,
  SUCCESS_UPDATE_CONTRACTOR,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const convertContractor = (contractor: any) => ({
  address: contractor.address,
  addressPol: contractor.addressPol,
  bankAddress: contractor.foreignBankDetails?.bankAddress,
  bankAddressEur: contractor.eurForeignBankDetails?.bankAddress,
  bankEur: contractor.eurForeignBankDetails?.bankName,
  bankUkrFo: contractor.bankFo,
  bankUkrFop: contractor.bankFop,
  bankUsd: contractor.foreignBankDetails?.bankName,
  contractTypeId: contractor.contractTypeId,
  correspondentBank: contractor.foreignBankDetails?.correspondentBank,
  correspondentBankEur: contractor.eurForeignBankDetails?.correspondentBank,
  correspondentBankSwift: contractor.foreignBankDetails
    ?.correspondentBankSwiftCode,
  correspondentBankSwiftEur: contractor.eurForeignBankDetails
    ?.correspondentBankSwiftCode,
  documentsLink: contractor.documentsLink,
  email: contractor.email,
  emailPersonal: contractor.emailPersonal,
  fullName: contractor.fullName,
  fullNameUkr: contractor.fullNameUkr,
  ibanEur: contractor.eurForeignBankDetails?.iban,
  ibanFo: contractor.ibanFo,
  ibanFop: contractor.ibanFop,
  ibanUsd: contractor.foreignBankDetails?.iban,
  id: contractor.id,
  inn: contractor.taxId,
  phone: contractor.phone,
  source: contractor.source,
  status: contractor.status,
  swift: contractor.foreignBankDetails?.bankSwiftCode,
  swiftEur: contractor.eurForeignBankDetails?.bankSwiftCode,
  taxGroup: contractor.taxGroup,
  workspaces: contractor.workspaces,
});

const initialState = {
  details: {},
  errorMessage: '',
  isFailed: false,
  isFailedCreate: false,
  isFailedUpdate: false,
  isFetching: false,
  isFetchingCreate: false,
  isFetchingUpdate: false,
  isFinishedCreate: false,
  isFinishedUpdate: false,
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_CONTRACTOR: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_CONTRACTOR: {
      const contractor = action.payload;

      const convertedContractor = convertContractor(contractor);

      return {
        ...state,
        details: convertedContractor,
        isFetching: false,
      };
    }

    case ERROR_RECEIVE_CONTRACTOR: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CREATE_CONTRACTOR: {
      return {
        ...state,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: true,
        isFinishedCreate: false,
      };
    }

    case REQUEST_UPDATE_CONTRACTOR: {
      return {
        ...state,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: true,
        isFinishedUpdate: false,
      };
    }

    case SUCCESS_CREATE_CONTRACTOR: {
      const contractor = action.payload;

      const newContractor = convertContractor(contractor);

      return {
        ...state,
        details: newContractor,
        errorMessage: '',
        isFailedCreate: false,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case SUCCESS_UPDATE_CONTRACTOR: {
      const contractor = action.payload;

      const newContractor = convertContractor(contractor);

      return {
        ...state,
        details: newContractor,
        errorMessage: '',
        isFailedUpdate: false,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case ERROR_CREATE_CONTRACTOR: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedCreate: true,
        isFetchingCreate: false,
        isFinishedCreate: true,
      };
    }

    case ERROR_UPDATE_CONTRACTOR: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;

      return {
        ...state,
        errorMessage,
        isFailedUpdate: true,
        isFetchingUpdate: false,
        isFinishedUpdate: true,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
