export const CLOCKIFY = 'CLOCKIFY';
export const NONE = 'NONE';
export const THE_SYSTEM = 'THE_SYSTEM';

const LIST = [
  CLOCKIFY,
  THE_SYSTEM,
  NONE,
];

export default LIST;
