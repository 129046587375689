import {
  CLEAR_AFTER_FETCH_STATUS,
  ERROR_DELETE_PAYROLL,
  ERROR_RECEIVE_PAYROLL,
  ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY,
  ERROR_RECEIVE_PAYROLL_ITEM,
  ERROR_RECEIVE_PAYROLLS,
  ERROR_REFRESH_PAYROLL,
  ERROR_REFRESH_PAYROLLS,
  ERROR_RECEIVE_SENT_INVOICES,
  ERROR_UPDATE_PAYROLL_ITEM,
  ERROR_UPDATE_PAYROLL_STATUS,
  RECEIVE_PAYROLL,
  RECEIVE_PAYROLL_CHANGE_HISTORY,
  RECEIVE_PAYROLL_ITEM,
  RECEIVE_PAYROLLS,
  REQUEST_DELETE_PAYROLL,
  REQUEST_PAYROLL,
  REQUEST_PAYROLL_CHANGE_HISTORY,
  REQUEST_PAYROLL_ITEM,
  REQUEST_PAYROLLS,
  REQUEST_REFRESH_PAYROLL,
  REQUEST_REFRESH_PAYROLLS,
  REQUEST_SENT_INVOICES,
  REQUEST_UPDATE_PAYROLL_ITEM,
  REQUEST_UPDATE_PAYROLL_STATUS,
  RESET_REDUCERS_DATA,
  SUCCESS_DELETE_PAYROLL,
  SUCCESS_REFRESH_PAYROLL,
  SUCCESS_REFRESH_PAYROLLS,
  RECEIVE_SENT_INVOICES,
  SUCCESS_UPDATE_PAYROLL_ITEM,
  SUCCESS_UPDATE_PAYROLL_STATUS,
  SUCCESS_RECEIVE_SENT_INVOICES,
} from '../constants/actionTypes';
import * as payrollStatuses from '../constants/payrollStatuses';
import sendingInvoicesStatuses from '../constants/sendingInvoicesStatuses';

type Action = {
  payload: any,
  type: string,
}

const getTotalHours = (items: any) => {
  const totalHours = (items || [])
    .reduce((acc: number, item: any) => item.hours
      ? acc + item.hours
      : acc, 0);
  return +Number(totalHours).toFixed(1);
};

const getTotalPercent = (items: any) => {
  const totalValue = (items || [])
    .reduce((acc: number, item: any) => item.percent
      ? acc + item.percent
      : acc, null);

  if (totalValue === null) {
    return null;
  }

  const percent = totalValue
    .toFixed(3)
    // remove trailing zeros
    .replace(/\.?0+$/, '');

  return parseFloat(percent);
};

const convertToSentPayrolls = (payrolls: any, payrollsIds: any) => {
  return payrolls.map((payroll: any) =>
    payrollsIds.includes(payroll.id)
      ? {
        ...payroll,
        status: payrollStatuses.SENT,
      }
      : payroll
  );
};

const convertPayrollItem = (item: any) => ({
  comment: item.comment,
  compensationFor: item.compensationFor,
  hours: item.hours,
  id: item.id,
  percent: item.percent,
  sourceHours: item.sourceHours,
  sum: item.sum,
});

const convertPayroll = (payroll: any) => ({
  contractor: payroll.contractorName,
  contractorId: payroll.contractorId,
  currency: payroll.currency,
  id: payroll.id,
  items: (payroll.items || [])
    .map((item: any) => convertPayrollItem(item)),
  status: payroll.status,
  statusDescription: payroll.statusDescription,
  sum: payroll.sum,
  totalHours: getTotalHours(payroll.items),
  totalPercent: getTotalPercent(payroll.items),
});

const initialState = {
  changes: [],
  errorText: '',
  isFailed: false,
  isFailedDeletePayroll:false,
  isFailedFetchingSentInvoices: false,
  isFailedPayroll: false,
  isFailedPayrollChangeHistory: false,
  isFailedPayrollItemDetails: false,
  isFailedRefresh: false,
  isFailedRefreshPayroll: false,
  isFailedUpdatePayrollItem: false,
  isFailedUpdatePayrollStatus: false,
  isFetching: false,
  isFetchingDeletePayroll: false,
  isFetchingPayroll: false,
  isFetchingPayrollChangeHistory: false,
  isFetchingPayrollItemDetails: false,
  isFetchingRefresh: false,
  isFetchingRefreshPayroll: false,
  isFetchingSentInvoices: false,
  isFetchingUpdatePayrollItem: false,
  isFetchingUpdatePayrollStatus: false,
  isFinishedPayrollChangeHistory: false,
  isFinishedPayrollItemDetails: false,
  isSuccessDeletePayroll: false,
  isSuccessFetchingSentInvoices: false,
  isSuccessRefresh: false,
  isSuccessRefreshPayroll: false,
  isSuccessUpdatePayrollItem: false,
  isSuccessUpdatePayrollStatus: false,
  itemDetails: {},
  list: [],
  sendingInvoicesStatus: '',
};

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case REQUEST_REFRESH_PAYROLLS: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: true,
        isSuccessRefresh: false,
      };
    }

    case SUCCESS_REFRESH_PAYROLLS: {
      return {
        ...state,
        isFailedRefresh: false,
        isFetchingRefresh: false,
        isSuccessRefresh: true,
      };
    }

    case ERROR_REFRESH_PAYROLLS: {
      return {
        ...state,
        isFailedRefresh: true,
        isFetchingRefresh: false,
        isSuccessRefresh: false,
      };
    }

    case REQUEST_PAYROLLS: {
      return {
        ...state,
        isFailed: false,
        isFetching: true,
      };
    }

    case RECEIVE_PAYROLLS: {
      const payrolls = action.payload;

      const list = payrolls.map((payroll: any) => convertPayroll(payroll));

      return {
        ...state,
        isFetching: false,
        list,
      };
    }

    case ERROR_RECEIVE_PAYROLLS: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_PAYROLL: {
      return {
        ...state,
        isFailedPayroll: false,
        isFetchingPayroll: true,
      };
    }

    case RECEIVE_PAYROLL: {
      const payroll = action.payload;

      const newPayroll = convertPayroll(payroll);
      const updatedList = state.list
        .map((payroll: any) => payroll.id === newPayroll.id
          ? newPayroll
          : payroll);

      return {
        ...state,
        isFailedPayroll: false,
        isFetchingPayroll: false,
        list: updatedList,
      };
    }

    case ERROR_RECEIVE_PAYROLL: {
      return {
        ...state,
        isFailedPayroll: true,
        isFetchingPayroll: false,
      };
    }

    case REQUEST_UPDATE_PAYROLL_STATUS: {
      return {
        ...state,
        errorText: '',
        isFailedUpdatePayrollStatus: false,
        isFetchingUpdatePayrollStatus: true,
        isSuccessUpdatePayrollStatus: false,
      };
    }

    case SUCCESS_UPDATE_PAYROLL_STATUS: {
      const payroll = action.payload;

      const updatedPayroll = convertPayroll(payroll);
      const updatedList = state.list
        .map((payroll: any) => payroll.id === updatedPayroll.id
          ? updatedPayroll
          : payroll);

      return {
        ...state,
        errorText: '',
        isFailedUpdatePayrollStatus: false,
        isFetchingUpdatePayrollStatus: false,
        isSuccessUpdatePayrollStatus: true,
        list: updatedList,
      };
    }

    case ERROR_UPDATE_PAYROLL_STATUS: {
      const error = action.payload;
      const errorMessage = error.response?.data?.message;
      return {
        ...state,
        errorText: errorMessage,
        isFailedUpdatePayrollStatus: true,
        isFetchingUpdatePayrollStatus: false,
        isSuccessUpdatePayrollStatus: false,
      };
    }

    case REQUEST_PAYROLL_ITEM: {
      return {
        ...state,
        isFailedPayrollItemDetails: false,
        isFetchingPayrollItemDetails: true,
        isFinishedPayrollItemDetails: false,
      };
    }

    case RECEIVE_PAYROLL_ITEM: {
      const payrollItem = action.payload;
      const itemDetails = {
        compensationFor: payrollItem.compensationFor,
        hours: payrollItem.hours,
        id: payrollItem.id,
        projectInvoiceHours: payrollItem.projectInvoiceHours,
        sourceHours: payrollItem.sourceHours,
      };

      return {
        ...state,
        isFetchingPayrollItemDetails: false,
        isFinishedPayrollItemDetails: true,
        itemDetails,
      };
    }

    case ERROR_RECEIVE_PAYROLL_ITEM: {
      return {
        ...state,
        isFailedPayrollItemDetails: true,
        isFetchingPayrollItemDetails: false,
        isFinishedPayrollItemDetails: true,
      };
    }

    case REQUEST_UPDATE_PAYROLL_ITEM: {
      return {
        ...state,
        isFailedUpdatePayrollItem: false,
        isFetchingUpdatePayrollItem: true,
        isSuccessUpdatePayrollItem: false,
      };
    }

    case SUCCESS_UPDATE_PAYROLL_ITEM: {
      const payroll = action.payload;

      const updatedPayroll = convertPayroll(payroll);
      const updatedList = state.list
        .map((payroll: any) => payroll.id === updatedPayroll.id
          ? updatedPayroll
          : payroll);

      return {
        ...state,
        isFailedUpdatePayrollItem: false,
        isFetchingUpdatePayrollItem: false,
        isSuccessUpdatePayrollItem: true,
        list: updatedList,
      };
    }

    case ERROR_UPDATE_PAYROLL_ITEM: {
      return {
        ...state,
        isFailedUpdatePayrollItem: true,
        isFetchingUpdatePayrollItem: false,
        isSuccessUpdatePayrollItem: false,
      };
    }

    case REQUEST_REFRESH_PAYROLL: {
      return {
        ...state,
        isFailedRefreshPayroll: false,
        isFetchingRefreshPayroll: true,
        isSuccessRefreshPayroll: false,
      };
    }

    case SUCCESS_REFRESH_PAYROLL: {
      const payroll = action.payload;

      const updatedPayroll = convertPayroll(payroll);
      const updatedList = state.list
        .map((payroll: any) => payroll.id === updatedPayroll.id
          ? updatedPayroll
          : payroll);

      return {
        ...state,
        isFailedRefreshPayroll: false,
        isFetchingRefreshPayroll: false,
        isSuccessRefreshPayroll: true,
        list: updatedList,
      };
    }

    case ERROR_REFRESH_PAYROLL: {
      return {
        ...state,
        isFailedRefreshPayroll: true,
        isFetchingRefreshPayroll: false,
        isSuccessRefreshPayroll: false,
      };
    }

    case REQUEST_PAYROLL_CHANGE_HISTORY: {
      return {
        ...state,
        changes: initialState.changes,
        isFailedPayrollChangeHistory: false,
        isFetchingPayrollChangeHistory: true,
        isFinishedPayrollChangeHistory: false,
      };
    }

    case RECEIVE_PAYROLL_CHANGE_HISTORY: {
      const changeHistory = action.payload;

      const changes =  changeHistory
        .reverse()
        .map((entry: any) => ({
          action: entry.action,
          currency: entry.currency,
          description: entry.changeDescription,
          status: entry.status,
          sum: entry.sum,
          timestamp: entry.date,
          user: entry.user,
        }));

      return {
        ...state,
        changes,
        isFailedPayrollChangeHistory: false,
        isFetchingPayrollChangeHistory: false,
        isFinishedPayrollChangeHistory: true,
      };
    }

    case ERROR_RECEIVE_PAYROLL_CHANGE_HISTORY: {
      return {
        ...state,
        isFailedPayrollChangeHistory: true,
        isFetchingPayrollChangeHistory: false,
        isFinishedPayrollChangeHistory: true,
      };
    }

    case REQUEST_DELETE_PAYROLL: {
      return {
        ...state,
        isFailedDeletePayroll: false,
        isFetchingDeletePayroll: true,
        isSuccessDeletePayroll: false,
      };
    }

    case SUCCESS_DELETE_PAYROLL: {
      const payrolls = action.payload;
      const list = payrolls.map((payroll: any) => convertPayroll(payroll));
      return {
        ...state,
        isFailedDeletePayroll: false,
        isFetchingDeletePayroll: false,
        isSuccessDeletePayroll: true,
        list,
      };
    }

    case ERROR_DELETE_PAYROLL: {
      return {
        ...state,
        isFailedDeletePayroll: true,
        isFetchingDeletePayroll: false,
        isSuccessDeletePayroll: false,
      };
    }

    case REQUEST_SENT_INVOICES: {
      return {
        ...state,
        isFailedFetchingSentInvoices: false,
        isFetchingSentInvoices: true,
        isSuccessFetchingSentInvoices: false,
        sendingInvoicesStatus: '',
      };
    }

    case RECEIVE_SENT_INVOICES: {
      const response = action.payload.statusResponse;
      const withAlert = action.payload.withAlert;
      const updatedList = convertToSentPayrolls(
        state.list,
        response.sentPayrollIds
      );
      const isFinished = response?.status === sendingInvoicesStatuses.FINISHED
        || response?.status === null;
      const isSuccess = isFinished && withAlert;
      const isFetching = !isFinished;

      return {
        ...state,
        isFailedFetchingSentInvoices: false,
        isFetchingSentInvoices: isFetching,
        isSuccessFetchingSentInvoices: isSuccess,
        list: updatedList,
        sendingInvoicesStatus: response.status,
      };
    }

    case SUCCESS_RECEIVE_SENT_INVOICES: {
      const response = action.payload;
      const updatedList = convertToSentPayrolls(
        state.list,
        response.sentPayrollIds
      );
      return {
        ...state,
        isFailedFetchingSentInvoices: false,
        isFetchingSentInvoices: false,
        isSuccessFetchingSentInvoices: true,
        list: updatedList,
        sendingInvoicesStatus: response.status,
      };
    }

    case ERROR_RECEIVE_SENT_INVOICES: {
      return {
        ...state,
        errorMessage: action.payload,
        isFailedFetchingSentInvoices: true,
        isFetchingSentInvoices: false,
        isSuccessFetchingSentInvoices: false,
      };
    }

    case CLEAR_AFTER_FETCH_STATUS: {
      return {
        ...state,
        isFailedDeletePayroll: false,
        isFailedFetchingSentInvoices: false,
        isFailedPayrollItemDetails: false,
        isFailedRefresh: false,
        isFailedRefreshPayroll: false,
        isFailedUpdatePayrollItem: false,
        isFailedUpdatePayrollStatus: false,
        isSuccessDeletePayroll: false,
        isSuccessFetchingSentInvoices: false,
        isSuccessRefresh: false,
        isSuccessRefreshPayroll: false,
        isSuccessUpdatePayrollItem: false,
        isSuccessUpdatePayrollStatus: false,
      };
    }

    case RESET_REDUCERS_DATA: return initialState;

    default: return state;
  }
};
