export const clientsAndProjects = 'clientsAndProjects';
export const commonSettings = 'settings/common';
export const compensationTypes = 'settings/compensationTypes';
export const costItems = 'settings/costItems';
export const contractors = 'contractors';
export const contractTypes = 'settings/contractTypes';
export const currencyRates = 'settings/currencyRates';
export const defaultPage = 'default';
export const expenses = 'expenses';
export const invoices = 'invoices';
export const login = 'login';
export const payrolls = 'payrolls';
export const reports = 'reports';
export const settings = 'settings';
export const taxes = 'settings/taxes';
export const taxTypes = 'settings/taxTypes';
export const userManagement = 'settings/userManagement';
