import {
  ERROR_RECEIVE_WORKSPACES,
  RECEIVE_WORKSPACES,
  REQUEST_WORKSPACES,
} from '../constants/actionTypes';
import config from 'config';
import axios from 'utils/requests';

const requestWorkspaces = () => ({
  type: REQUEST_WORKSPACES,
});

const receiveWorkspaces = (response: any) => ({
  payload: response,
  type: RECEIVE_WORKSPACES,
});

const errorReceiveWorkspaces = () => ({
  type: ERROR_RECEIVE_WORKSPACES,
});

const getWorkspaces = () => {
  const {
    BASE_URL,
    WORKSPACE_SERVICE,
  } = config;
  return axios.get(`${BASE_URL}${WORKSPACE_SERVICE}`)
    .then(({ data }) => data);
};

const fetchWorkspaces = () => (dispatch: any) => {
  dispatch(requestWorkspaces());
  return getWorkspaces()
    .then(response => dispatch(receiveWorkspaces(response)))
    .catch(() => dispatch(errorReceiveWorkspaces()));
};

const exportFunctions = {
  fetchWorkspaces,
};

export default exportFunctions;
