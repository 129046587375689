import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconEdit from 'components/icons/Edit';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import orderedCurrencies, * as currencies from 'constants/currencies';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useTheme from 'hooks/useTheme';

import actionsExpenses from '../actions/expenses';
import actionsSetup from '../actions/setup';
import moment from 'moment';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsInnerContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  selectQuarter: {
    width: '118px',
  },
  selectYear: {
    width: '80px',
  },
  sum: {
    width: '150px',
  },
  tableCell: {
    alignItems: 'center',
    display: 'flex',
    height: '16px',
  },
}));

const TABLE_COLUMNS = {
  COMMENT: 'COMMENT',
  CURRENCY: 'CURRENCY',
  NAME: 'NAME',
};

const SUM_FIELDS = {
  SUM_FACT: 'sumFact',
  SUM_PLANNED: 'sumPlanned',
};

const changeExpenseFieldByColumnName = {
  [TABLE_COLUMNS.COMMENT]: ({ expense, value }: any) => ({
    ...expense,
    comment: value,
  }),
  [TABLE_COLUMNS.CURRENCY]: ({ expense, value }: any) => ({
    ...expense,
    currency: value,
  }),
};

const CURRENT_QUARTER = moment().quarter();
const CURRENT_YEAR = moment().year();
const YEARS_FORWARD = 3;
const YEARS_BACKWARD = 5;

const quarters = Array
  .from(new Array(4).keys())
  .map(key=> key + 1);

const quarterToMonths: { [key: number]: number[] } = {
  1: [1, 2, 3],
  2: [4, 5, 6],
  3: [7, 8, 9],
  4: [10, 11, 12],
};

const years = [
  ...Array
    .from(new Array(YEARS_FORWARD).keys())
    .map(index => CURRENT_YEAR + index)
    .reverse(),
  ...Array
    .from(new Array(YEARS_BACKWARD).keys())
    .map(index => CURRENT_YEAR - (index + 1)),
];

const convertExpensesToBE = (expenses: any) => expenses
  .map((expense: any) => ({
    comment: expense.comment,
    costItemId: expense.id,
    currency: expense.currency,
    monthSums: expense.monthSums.map((month: any) => ({
      monthNumber: month.monthNumber,
      sumFact: month.sumFact,
      sumPlanned: month.sumPlanned,
    })),
  }));

function Expenses() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();

  const {
    isFailed: isFailedExpenses,
    isFailedUpdate: isFailedUpdateExpenses,
    isFetching: isFetchingExpenses,
    isFetchingUpdate: isFetchingUpdateExpenses,
    isSuccessUpdate: isSuccessUpdateExpenses,
    list: expenses,
  } = useSelector(({ expenses: reducerExpenses }: any) => reducerExpenses);

  const [state, setState] = useState({
    compactColumnIndex: 1,
    expenses: [] as any,
    isEditMode: false,
    quarter: CURRENT_QUARTER,
    showAfterSaveAlert: false,
    year: CURRENT_YEAR,
  });

  const monthsForQuarter = useMemo(
    () => quarterToMonths[state.quarter],
    [state.quarter]
  );

  const isFailed = isFailedExpenses;
  const isFetching = isFetchingExpenses;

  const onCancelEdit = () => setState({
    ...state,
    expenses,
    isEditMode: false,
    showAfterSaveAlert: false,
  });

  const onCloseAlert = () => setState((prevState: any) => ({
    ...prevState,
    showAfterSaveAlert: false,
  }));

  const onChangeExpenseValue = ({
    columnName,
    expenseId,
    value,
  }: any) => {
    setState({
      ...state,
      expenses: state.expenses
        .map((expense: any) => expense.id === expenseId
          ? changeExpenseFieldByColumnName[columnName]({
            expense,
            value,
          })
          : expense),
    });
  };

  const updateMonthSums = ({
    expense,
    field,
    monthNumber,
    value,
  }: any) => {
    if (expense.monthSums.some((month: any) =>
      month.monthNumber === monthNumber)
    ) {
      return expense.monthSums.map((month: any) =>
        month.monthNumber === monthNumber
          ? {
            ...month,
            [field]: value,
          }
          : month
      );
    } else {
      return [
        ...expense.monthSums,
        {
          [field]: value,
          monthNumber: monthNumber,
        },
      ];
    }
  };

  const onChangeMonthSumValue = ({
    expenseId,
    monthNumber,
    value,
    field,
  }: any) => {
    let { expenses } = state;

    expenses = expenses.map((expense: any) =>
      expense.id === expenseId
        ? {
          ...expense,
          monthSums: updateMonthSums({
            expense,
            field,
            monthNumber,
            value,
          }),
        }
        : expense
    );

    setState({
      ...state,
      expenses,
    });
  };

  const tableRows = useMemo(() => (state.expenses
    .map((expense: any) => ({
      id: expense.id,
      months: expense.monthSums,
      [TABLE_COLUMNS.NAME]: {
        label: expense.name,
        value: expense.name,
      },
      [TABLE_COLUMNS.COMMENT]: {
        label: expense.comment,
        value: expense.comment,
      },
      [TABLE_COLUMNS.CURRENCY]: {
        label: expense.currency,
        value: expense.currency,
      },
    }))
    .sort((row1: any, row2: any) => row1[TABLE_COLUMNS.NAME].label
      .localeCompare(row2[TABLE_COLUMNS.NAME].label))
  ), [state.expenses]);

  useEffect(() => {
    dispatch(actionsExpenses.fetchExpenses({
      quarter: state.quarter,
      year: state.year,
    }));
  }, [state.quarter, state.year]);

  useEffect(() => {
    if (!!expenses.length) {
      setState(prevState => ({
        ...prevState,
        expenses,
      }));
    }
  }, [expenses]);

  useEffect(() => {
    if (isSuccessUpdateExpenses || isFailedUpdateExpenses) {
      setState(prevState => ({
        ...prevState,
        isEditMode: isFailedUpdateExpenses,
        showAfterSaveAlert: true,
      }));
    }
  }, [isFailedUpdateExpenses, isSuccessUpdateExpenses]);

  useEffect(() => {
    return () => {
      dispatch(actionsSetup.resetReducer());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'expenses' })}
          variant="text"
        />
      </Breadcrumbs>
      <div className={classes.actionsContainer}>
        <div className={classes.actionsInnerContainer}>
          <div className={classes.selectQuarter}>
            <Select
              disabled={state.isEditMode || isFetching}
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                quarter: target.value,
              })}
              size="small"
              value={state.quarter}
              variant="outlined"
            >
              {quarters.map((quarter) => (
                <MenuItem value={quarter}>
                  <Typography>
                    {formatMessage({
                      id: `quarter.${quarter}`,
                    })}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectYear}>
            <Select
              disabled={state.isEditMode || isFetching}
              fullWidth
              onChange={({ target }) => setState({
                ...state,
                year: target.value,
              })}
              size="small"
              value={state.year}
              variant="outlined"
            >
              {years.map(year => (
                <MenuItem value={year}>
                  <Typography>
                    {year}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {(isFetching || isFailed)
        && (
          <Loading variant={isFailed ? 'error' : 'loading'}>
            {isFailed && (
              <Typography
                color="secondary"
                variant="subtitle"
              >
                {formatMessage({ id: 'loading.error' })}
              </Typography>
            )}
          </Loading>
        )}
      {!isFailed && !isFetching && !expenses.length
        && (
          <Loading variant="noData">
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage({ id: 'loading.noData' })}
            </Typography>
          </Loading>
        )}
      {!isFetching && !isFailed && !!expenses.length
        && (
          <Card variant={state.isEditMode ? 'edit' : 'paper'}>
            <CardTitle>
              <Typography
                color="secondary"
                variant="title"
              >
                {formatMessage({ id: 'tableTitle' })}
              </Typography>
              {state.isEditMode && (
                <IconButton onClick={onCancelEdit}>
                  <IconClose size={24} />
                </IconButton>
              )}
              {!state.isEditMode && (
                <IconButton
                  onClick={() => setState({
                    ...state,
                    expenses: state.expenses
                      .map((expense: any) => ({
                        ...expense,
                        currency: expense.currency || currencies.USD,
                      })),
                    isEditMode: true,
                  })}
                >
                  <IconEdit size={20} />
                </IconButton>
              )}
            </CardTitle>
            <CardContent disablePadding>
              <Table
                compactColumnIndex={state.compactColumnIndex}
                compact={isMobile}
                fixed
                onChangeCompactColumnIndex={(index: number) => setState({
                  ...state,
                  compactColumnIndex: index,
                })}
              >
                <TableHead>
                  <TableRow>
                    <>
                      <TableCellHead
                        compactVariant="static"
                        size="smallField"
                      >
                        <Typography
                          color="secondary"
                          noWrap
                          variant="caption"
                        >
                          {formatMessage({
                            id: `tableColumn.${TABLE_COLUMNS.NAME}`,
                          })}
                        </Typography>
                      </TableCellHead>
                      <TableCellHead
                        compactVariant="dynamic"
                        size="smallField"
                      >
                        <Typography
                          color="secondary"
                          noWrap
                          variant="caption"
                        >
                          {formatMessage({
                            id: `tableColumn.${TABLE_COLUMNS.COMMENT}`,
                          })}
                        </Typography>
                      </TableCellHead>
                      {monthsForQuarter.map(month => (
                        <>
                          <TableCellHead
                            compactVariant="dynamic"
                            size="value"
                          >
                            <Typography
                              color="secondary"
                              noWrap
                              variant="caption"
                            >
                              {formatMessage(
                                { id: 'month.plan' },
                                {
                                  month: formatMessage({
                                    id: `month.${month}`,
                                  }),
                                }
                              )}
                            </Typography>
                          </TableCellHead>
                          <TableCellHead
                            compactVariant="dynamic"
                            size="value"
                          >
                            <Typography
                              color="secondary"
                              noWrap
                              variant="caption"
                            >
                              {formatMessage(
                                { id: 'month.fact' },
                                {
                                  month: formatMessage({
                                    id: `month.${month}`,
                                  }),
                                }
                              )}
                            </Typography>
                          </TableCellHead>
                        </>
                      ))}
                      <TableCellHead
                        compactVariant="dynamic"
                        size="change"
                      >
                        <Typography
                          color="secondary"
                          noWrap
                          variant="caption"
                        >
                          {formatMessage({
                            id: 'currency',
                          })}
                        </Typography>
                      </TableCellHead>
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row: any) => (
                    <TableRow>
                      <TableCell>
                        <Typography noWrap>
                          {row[TABLE_COLUMNS.NAME].label}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {!state.isEditMode && (
                          <Tooltip title={row[TABLE_COLUMNS.COMMENT].label}>
                            <Typography noWrap>
                              {row[TABLE_COLUMNS.COMMENT].label}
                            </Typography>
                          </Tooltip>
                        )}
                        {state.isEditMode && (
                          <div className={classes.tableCell}>
                            <TextField
                              fullWidth
                              onChange={({ target }) =>
                                onChangeExpenseValue({
                                  columnName: TABLE_COLUMNS.COMMENT,
                                  expenseId: row.id,
                                  value: target.value,
                                })}
                              value={row[TABLE_COLUMNS.COMMENT].value}
                              variant="standard"
                            />
                          </div>
                        )}
                      </TableCell>
                      {row.months.map((month: any) => (
                        <>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMode && (
                                <Typography>
                                  {month.sumPlanned}
                                </Typography>
                              )}
                              {state.isEditMode && (
                                <div className={classes.sum}>
                                  <TextField
                                    fullWidth
                                    inputType="number"
                                    onChange={({ target }) =>
                                      onChangeMonthSumValue({
                                        expenseId: row.id,
                                        field: SUM_FIELDS.SUM_PLANNED,
                                        monthNumber: month.monthNumber,
                                        value: target.value,
                                      })}
                                    value={month.sumPlanned}
                                    variant="standard"
                                  />
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.tableCell}>
                              {!state.isEditMode && (
                                <Typography>
                                  {month.sumFact}
                                </Typography>
                              )}
                              {state.isEditMode && (
                                <div className={classes.sum}>
                                  <TextField
                                    fullWidth
                                    inputType="number"
                                    onChange={({ target }) =>
                                      onChangeMonthSumValue({
                                        expenseId: row.id,
                                        field: SUM_FIELDS.SUM_FACT,
                                        monthNumber: month.monthNumber,
                                        value: target.value,
                                      })}
                                    value={month.sumFact}
                                    variant="standard"
                                  />
                                </div>
                              )}
                            </div>
                          </TableCell>
                        </>
                      ))}
                      <TableCell>
                        <div className={classes.tableCell}>
                          {!state.isEditMode && (
                            <Typography>
                              {row[TABLE_COLUMNS.CURRENCY].label}
                            </Typography>
                          )}
                          {state.isEditMode && (
                            <div className={classes.sum}>
                              <TextField
                                AdornmentEnd={(
                                  <Select
                                    disableUnderline
                                    onChange={({ target }) =>
                                      onChangeExpenseValue({
                                        columnName: TABLE_COLUMNS.CURRENCY,
                                        expenseId: row.id,
                                        value: target.value,
                                      })}
                                    value={row[TABLE_COLUMNS.CURRENCY].value}
                                  >
                                    {orderedCurrencies
                                      .map((currency: any) => (
                                        <MenuItem value={currency}>
                                          <Typography>
                                            {currency}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                  </Select>
                                )}
                                fullWidth
                                inputType="number"
                                onChange={({ target }) =>
                                  onChangeExpenseValue({
                                    columnName: TABLE_COLUMNS.CURRENCY,
                                    expenseId: row.id,
                                    value: target.value,
                                  })}
                                value={row[TABLE_COLUMNS.CURRENCY].value}
                                variant="standard"
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
            {state.isEditMode && (
              <CardActions>
                <Button
                  onClick={onCancelEdit}
                  variant="secondary"
                >
                  <Typography color="inherit">
                    {formatMessage({ id: 'cancel' })}
                  </Typography>
                </Button>
                <Button
                  isLoading={isFetchingUpdateExpenses}
                  onClick={() => dispatch(actionsExpenses
                    .fetchUpdateExpenses({
                      expenses: convertExpensesToBE(state.expenses),
                      quarter: state.quarter,
                      year: state.year,
                    }))}
                  variant="primary"
                >
                  <Typography color="inherit">
                    {formatMessage({ id: 'save' })}
                  </Typography>
                </Button>
              </CardActions>
            )}
          </Card>
        )}

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={onCloseAlert}
        open={state.showAfterSaveAlert && isSuccessUpdateExpenses}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'save.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar
        onClose={(event: any, reason: string) => {
          if (reason !== 'clickaway') {
            onCloseAlert();
          }
        }}
        open={state.showAfterSaveAlert && isFailedUpdateExpenses}
      >
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage({ id: 'save.error' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseAlert}
            >
              <IconClose size={24}/>
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Expenses;
