import {
  REQUEST_REFRESH_CONTRACTORS,
  SUCCESS_REFRESH_CONTRACTORS,
  ERROR_REFRESH_CONTRACTORS,
  RECEIVE_CONTRACTORS,
  REQUEST_CONTRACTORS,
  ERROR_RECEIVE_CONTRACTORS,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

interface GetContractorsParams {
  contractTypeId: string | null,
  pageIndex: number,
  pageSize: number,
  searchText: string,
  status: string,
  source: string | null,
  workspace: string | null,
}

const requestContractors = () => ({
  type: REQUEST_CONTRACTORS,
});

const receiveContractors = (response: any) => ({
  payload: response,
  type: RECEIVE_CONTRACTORS,
});

const errorReceiveContractors = () => ({
  type: ERROR_RECEIVE_CONTRACTORS,
});

const getContractors = ({
  contractTypeId,
  pageIndex,
  pageSize,
  searchText,
  source,
  status,
  workspace,
}: GetContractorsParams) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.post(`${BASE_URL}${CONTRACTORS_SERVICE}/_search`,
    {
      contractTypeId,
      contractorSource: source,
      name: searchText,
      page: pageIndex,
      size: pageSize,
      status,
      workspaceId: workspace,
    }).then(({ data }) => data);
};

const fetchContractors = ({
  contractTypeId,
  pageIndex,
  pageSize,
  searchText,
  status,
  source,
  workspace,
}: GetContractorsParams) => (dispatch: any) => {
  dispatch(requestContractors());
  return getContractors({
    contractTypeId,
    pageIndex,
    pageSize,
    searchText,
    source,
    status,
    workspace,
  }).then(response => dispatch(receiveContractors(response)))
    .catch(() => dispatch(errorReceiveContractors()));
};

const requestRefreshContractors = () => ({
  type: REQUEST_REFRESH_CONTRACTORS,
});

const successRefreshContractors = () => ({
  type: SUCCESS_REFRESH_CONTRACTORS,
});

const errorRefreshContractors = () => ({
  type: ERROR_REFRESH_CONTRACTORS,
});

const refreshContractors = () => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.post(`${BASE_URL}${CONTRACTORS_SERVICE}/refresh`);
};

const fetchRefreshContractors = ({
  contractTypeId,
  pageIndex,
  pageSize,
  searchText,
  status,
  source,
  workspace,
}: GetContractorsParams) => (dispatch: any) => {
  dispatch(requestRefreshContractors());
  return refreshContractors()
    .then(() => {
      dispatch(successRefreshContractors());
      return dispatch(fetchContractors({
        contractTypeId,
        pageIndex,
        pageSize,
        searchText,
        source,
        status,
        workspace,
      }));
    })
    .catch(() => dispatch(errorRefreshContractors()));
};

const exportFunctions = {
  fetchContractors,
  fetchRefreshContractors,
};

export default exportFunctions;
