const extractFileName = (header: string): string | null => {
  if (!header) return null;
  // Try UTF-8 encoded filename first
  const utf8Match = header.match(/filename\*=UTF-8''([^"]+)/)?.[1];
  if (utf8Match) {
    try {
      return decodeURIComponent(utf8Match);
    } catch (e) {
      console.warn('Failed to decode UTF-8 filename:', e);
      // Fall through to try regular filename
    }
  }
  // Try regular filename
  const regularMatch = header.match(/filename="([^"]+)""/)?.[1];
  return regularMatch || null;
};

export {
  extractFileName
};
