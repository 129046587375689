export const CLOCKIFY = 'CLOCKIFY';
export const NONE = 'NONE';
export const THE_SYSTEM = 'THE_SYSTEM';

export const ALL = [
  CLOCKIFY,
  THE_SYSTEM,
  NONE,
];

export const CREATION_OPTIONS = [
  THE_SYSTEM,
  NONE,
];

export default ALL;
