import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Checkbox from 'components/Checkbox';
import Chip from 'components/Chip';
import Colon from 'components/Colon';
import Dialog from 'components/Dialog';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconPlus from 'components/icons/Plus';
import IconCheckMultiple from 'components/icons/CheckMultiple';
import Loading from 'components/Loading';
import Mandatory from 'components/Mandatory';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellActions from 'components/TableCellActions';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import actionsContractTypes from '../actions/contractTypes';
import actionsInvoiceForms from '../actions/invoiceForms';
import actionsRegistryForms from '../actions/registryForms';
import actionsSetup from '../actions/setup';
import actionsTaxTypes from '../actions/taxTypes';
import bankDetailsTypes, { UAH_IE }  from '../constants/bankDetailsTypes';
import pagesURLs from 'constants/pagesURLs';
import * as pages from 'constants/pages';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  cell: {
    minWidth: '50%',
  },
  cellContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  detailsTooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(0.5)}px`,
  },
  dialogContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
    overflow: 'hidden',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '20px',
  },
}));

const ICON_SIZE = 20;
const ICON_SIZE_TITLE = 24;

const errorTypes = {
  EMPTY_FIELD_TITLE: 'EMPTY_FIELD_TITLE',
};

const TABLE_COLUMNS = {
  actions: 'ACTIONS',
  active: 'ACTIVE',
  bankDetails: 'BANK_DETAILS',
  details: 'DETAILS',
  invoiceForm: 'INVOICE_FORM',
  name: 'NAME',
  registryForm: 'REGISTRY_FORM',
  taxTypesCompensatedByCompany: 'TAX_TYPES_COMPENSATED_BY_COMPANY',
  taxTypesPaidByCompany: 'TAX_TYPES_PAID_BY_COMPANY',
};

const ContractTypes = () => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const { lang } = useLocationSearch();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();

  const {
    errorMessage,
    isFailed: isFailedContractTypes,
    isFailedCreate,
    isFailedDelete,
    isFailedUpdate,
    isFetching: isFetchingContractTypes,
    isFetchingCreate,
    isFetchingDelete,
    isFetchingUpdate,
    isFinishedCreate,
    isFinishedDelete,
    isFinishedUpdate,
    list: contractTypesList,
  } = useSelector(({
    contractTypes: reducerContractTypes,
  }: any) => reducerContractTypes);

  const {
    isFailed: isFailedTaxTypes,
    isFetching: isFetchingTaxTypes,
    list: taxTypes,
  } = useSelector(({ taxTypes: reducerTaxTypes }) => reducerTaxTypes);

  const {
    isFailed: isFailedInvoiceForms,
    isFetching: isFetchingInvoiceForms,
    list: invoiceForms,
  } = useSelector(({
    invoiceForms: reducerInvoiceForms,
  }) => reducerInvoiceForms);

  const {
    isFailed: isFailedRegistryForms,
    isFetching: isFetchingRegistryForms,
    list: registryForms,
  } = useSelector(({
    registryForms: reducerRegistryForms,
  }) => reducerRegistryForms);

  const [state, setState] = useState({
    active: false,
    bankDetails: '',
    compactColumnIndex: 1,
    includeInPolishPaymentRegistry: false,
    invoiceForm : '',
    isRoundPayrollSum: false,
    registryForm: '',
    shouldSendInvoiceInEmail: false,
    showCreateDialog: false,
    showErrorAfterSaveAlert: false,
    showSuccessAfterSaveAlert: false,
    showSumInUahInEmail: false,
    taxTypeIdsCompensatedByCompany: [] as any,
    taxTypeIdsPaidByCompany: [] as any,
    typeId: '',
    typeIdToDelete: '',
    typeName: '',
    validationErrors: [] as string[],
  });

  const isFailed = isFailedContractTypes
    || isFailedTaxTypes
    || isFailedInvoiceForms
    || isFailedRegistryForms;
  const isFetching = isFetchingContractTypes
    || isFetchingTaxTypes
    || isFetchingInvoiceForms
    || isFetchingRegistryForms;

  const getValidationErrors = () => {
    const errors = [];
    if (!state.typeName.trim()) {
      errors.push(errorTypes.EMPTY_FIELD_TITLE);
    }

    return errors;
  };

  const onCloseCreateUpdateDialog = () => setState({
    ...state,
    showCreateDialog: false,
    showErrorAfterSaveAlert: false,
    typeId: '',
  });

  const onCloseDeleteDialog = () => setState({
    ...state,
    showErrorAfterSaveAlert: false,
    typeIdToDelete: '',
  });

  const onChangeTypeName = (value: string) => {
    setState((prevState: any) => ({
      ...prevState,
      typeName: value,
      validationErrors: prevState.validationErrors
        .filter((err: string) => err !== errorTypes.EMPTY_FIELD_TITLE),
    }));
  };

  const onEditRowClick = (row: any) => setState({
    ...state,
    active: row[TABLE_COLUMNS.active].value,
    bankDetails: row[TABLE_COLUMNS.bankDetails].value,
    includeInPolishPaymentRegistry: row[TABLE_COLUMNS.details]
      .includeInPolishPaymentRegistry.value,
    invoiceForm: row[TABLE_COLUMNS.invoiceForm].value,
    isRoundPayrollSum: row[TABLE_COLUMNS.details].isRoundPayrollSum.value,
    registryForm: row[TABLE_COLUMNS.registryForm].value,
    shouldSendInvoiceInEmail: row[TABLE_COLUMNS.details]
      .shouldSendInvoiceInEmail.value,
    showSumInUahInEmail: row[TABLE_COLUMNS.details].showSumInUahInEmail.value,
    taxTypeIdsCompensatedByCompany: row[TABLE_COLUMNS
      .taxTypesCompensatedByCompany].value,
    taxTypeIdsPaidByCompany: row[TABLE_COLUMNS.taxTypesPaidByCompany].value,
    typeId: row.typeId,
    typeName: row[TABLE_COLUMNS.name].value,
  });

  const onSaveContractType = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      if (state.typeId) {
        dispatch(actionsContractTypes.fetchUpdateType({
          active: state.active,
          bankDetailsType: state.bankDetails,
          includeInPolishPaymentRegistry: state.includeInPolishPaymentRegistry,
          invoiceFormCode: state.invoiceForm,
          isRoundPayrollSum: state.isRoundPayrollSum,
          name: state.typeName,
          payRegistryFormCode: state.registryForm,
          shouldSendInvoiceInEmail: state.shouldSendInvoiceInEmail,
          showSumInUahInEmail: state.showSumInUahInEmail,
          taxTypeIdsCompensatedByCompany: state.taxTypeIdsCompensatedByCompany,
          taxTypeIdsPaidByCompany: state.taxTypeIdsPaidByCompany,
          typeId: state.typeId,
        }));
      } else {
        dispatch(actionsContractTypes.fetchCreateType({
          active: state.active,
          bankDetailsType: state.bankDetails,
          includeInPolishPaymentRegistry: state.includeInPolishPaymentRegistry,
          invoiceFormCode: state.invoiceForm,
          isRoundPayrollSum: state.isRoundPayrollSum,
          name: state.typeName,
          payRegistryFormCode: state.registryForm,
          shouldSendInvoiceInEmail: state.shouldSendInvoiceInEmail,
          showSumInUahInEmail: state.showSumInUahInEmail,
          taxTypeIdsCompensatedByCompany: state.taxTypeIdsCompensatedByCompany,
          taxTypeIdsPaidByCompany: state.taxTypeIdsPaidByCompany,
        }));
      }
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const onDeleteContractType = () => {
    dispatch(actionsContractTypes.fetchDeleteType({
      typeId: state.typeIdToDelete,
    }));
  };

  const tableRows = useMemo(() => (contractTypesList
    .map((type: any) => ({
      typeId: type.id,
      [TABLE_COLUMNS.name]: {
        label: type.name,
        value: type.name,
      },
      [TABLE_COLUMNS.bankDetails]: {
        label: formatMessage({ id: `bankDetails.${type.bankDetails}` }),
        value: type.bankDetails,
      },
      [TABLE_COLUMNS.taxTypesPaidByCompany]: {
        hint: !!type.taxTypeIdsPaidByCompany?.length
          ? type.taxTypeIdsPaidByCompany
            .map((typeId: any) => taxTypes
              .find((taxType: any) => taxType.id === typeId)?.name)
            .join(', ')
          : null,
        label: type.taxTypeIdsPaidByCompany
          ?.length || formatMessage({ id: 'no' }),
        value: type.taxTypeIdsPaidByCompany,
      },
      [TABLE_COLUMNS.taxTypesCompensatedByCompany]: {
        hint: !!type.taxTypeIdsCompensatedByCompany?.length
          ? type.taxTypeIdsCompensatedByCompany
            .map((typeId: any) => taxTypes
              .find((taxType: any) => taxType.id === typeId)?.name)
            .join(', ')
          : null,
        label: type.taxTypeIdsCompensatedByCompany
          ?.length || formatMessage({ id: 'no' }),
        value: type.taxTypeIdsCompensatedByCompany,
      },
      [TABLE_COLUMNS.invoiceForm]: {
        label: invoiceForms
          .find(({ id }: any) => type.invoiceForm === id)?.name
          || type.invoiceForm,
        value: type.invoiceForm,
      },
      [TABLE_COLUMNS.registryForm]: {
        label: registryForms
          .find(({ id }: any) => type.registryForm === id)?.name
          || type.registryForm,
        value: type.registryForm,
      },
      [TABLE_COLUMNS.details]: {
        includeInPolishPaymentRegistry: {
          hint: formatMessage(
            { id: 'table.includeInPolishPaymentRegistry' },
            {
              value: formatMessage({
                id: type.details.includeInPolishPaymentRegistry ? 'yes' : 'no',
              }),
            }
          ),
          value: type.details.includeInPolishPaymentRegistry,
        },
        isRoundPayrollSum: {
          hint: formatMessage(
            { id: 'table.roundPayroll' },
            {
              value: formatMessage({
                id: type.details.isRoundPayrollSum ? 'yes' : 'no',
              }),
            }
          ),
          value: type.details.isRoundPayrollSum,
        },
        shouldSendInvoiceInEmail: {
          hint: formatMessage(
            { id: 'table.sendInvoiceInEmail' },
            {
              value: formatMessage({
                id: type.details.shouldSendInvoiceInEmail ? 'yes' : 'no',
              }),
            }
          ),
          value: type.details.shouldSendInvoiceInEmail,
        },
        showSumInUahInEmail: {
          hint: formatMessage(
            { id: 'table.showSumInUahInEmail' },
            {
              value: formatMessage({
                id: type.details.showSumInUahInEmail ? 'yes' : 'no',
              }),
            }
          ),
          value: type.details.showSumInUahInEmail,
        },
      },
      [TABLE_COLUMNS.active]: {
        label: formatMessage({ id: type.active ? 'yes' : 'no' }),
        value: type.active,
      },
    }))
  ), [
    invoiceForms,
    lang,
    contractTypesList,
    registryForms,
    taxTypes,
  ]);

  const taxTypeMapping = useMemo(() => {
    return taxTypes.reduce((acc: any, type: any) => {
      if (type?.id && type?.name) {
        acc[type.id] = type.name;
      }
      return acc;
    }, {});
  }, [taxTypes]);

  const formatTaxTypeNames = (typeIds: any[], taxTypeMapping: any) => {
    return typeIds
      ?.map((typeId) => taxTypeMapping[typeId])
      .filter((name) => !!name)
      .join(', ');
  };

  useEffect(() => {
    let {
      showCreateDialog,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedCreate) {
      showCreateDialog = isFailedCreate;
      showSuccessAfterSaveAlert = !isFailedCreate;
    }

    setState({
      ...state,
      showCreateDialog,
      showErrorAfterSaveAlert: isFailedCreate,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingCreate]);

  useEffect(() => {
    let {
      showSuccessAfterSaveAlert,
      typeId,
    } = state;

    if (isFinishedUpdate) {
      showSuccessAfterSaveAlert = !isFailedUpdate;
      typeId = isFailedUpdate ? state.typeId : '';
    }

    setState({
      ...state,
      showErrorAfterSaveAlert: isFailedUpdate,
      showSuccessAfterSaveAlert,
      typeId,
    });
  }, [isFetchingUpdate]);

  useEffect(() => {
    let {
      showSuccessAfterSaveAlert,
      typeId,
      typeIdToDelete,
    } = state;

    if (isFinishedDelete) {
      showSuccessAfterSaveAlert = !isFailedDelete;
      typeId = isFailedDelete ? state.typeId : '';
      typeIdToDelete = isFailedDelete ? state.typeIdToDelete : '';
    }

    setState({
      ...state,
      showCreateDialog: false,
      showErrorAfterSaveAlert: isFailedDelete,
      showSuccessAfterSaveAlert,
      typeId,
      typeIdToDelete,
    });
  }, [isFetchingDelete]);

  useEffect(() => {
    dispatch(actionsContractTypes.fetchTypes());
    dispatch(actionsInvoiceForms.fetchInvoiceForms());
    dispatch(actionsRegistryForms.fetchRegistryForms());
    dispatch(actionsTaxTypes.fetchTaxTypes());

    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          to={{ pathname: `${pagesURLs[pages.settings]}` }}
          variant="link"
        />
        <Breadcrumb
          label={formatMessage({ id: 'contractTypes' })}
          variant="text"
        />
      </Breadcrumbs>
      {(isFetching || isFailed) && (
        <Loading variant={isFailed ? 'error' : 'loading'}>
          {isFailed && (
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {!isFetching && !isFailed && (
        <>
          <Card>
            <CardTitle>
              <Typography color="secondary">
                {formatMessage({ id: 'create.subtitle' })}
              </Typography>
              <Button
                onClick={() => setState({
                  ...state,
                  active: true,
                  bankDetails: UAH_IE,
                  includeInPolishPaymentRegistry: false,
                  invoiceForm: '',
                  isRoundPayrollSum: false,
                  registryForm: '',
                  shouldSendInvoiceInEmail: false,
                  showCreateDialog: true,
                  showSumInUahInEmail: true,
                  taxTypeIdsPaidByCompany: [],
                  typeId: '',
                  typeName: '',
                })}
                variant="primary"
                startIcon={(
                  <IconPlus
                    color="button"
                    size={ICON_SIZE}
                  />
                )}
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'add' })}
                </Typography>
              </Button>
            </CardTitle>
          </Card>
          <Card disablePaddings>
            <Table
              compact={isMobile}
              compactColumnIndex={state.compactColumnIndex}
              fixed
              onChangeCompactColumnIndex={(index) => setState({
                ...state,
                compactColumnIndex: index,
              })}
            >
              <TableHead>
                <TableRow>
                  <TableCellHead compactVariant="static">
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.name}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'bars' : undefined}
                  >
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.bankDetails}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'smallField' : undefined}
                  >
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.registryForm}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'mediumField' : undefined}
                  >
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.invoiceForm}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'value' : undefined}
                  >
                    <Tooltip
                      title={formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS
                          .taxTypesPaidByCompany}`,
                      })}
                    >
                      <Typography
                        align="center"
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS
                            .taxTypesPaidByCompany}`,
                        })}
                      </Typography>
                    </Tooltip>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'value' : undefined}
                  >
                    <Tooltip
                      title={formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS
                          .taxTypesCompensatedByCompany}`,
                      })}
                    >
                      <Typography
                        align="center"
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS
                            .taxTypesCompensatedByCompany}`,
                        })}
                      </Typography>
                    </Tooltip>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'change' : undefined}
                  >
                    <Typography
                      align="center"
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.details}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                    size={!isMobile ? 'change' : undefined}
                  >
                    <Typography
                      align="center"
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.active}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  {!isMobile && (
                    <TableCellHead size="bars">
                      <Typography
                        align="center"
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.actions}`,
                        })}
                      </Typography>
                    </TableCellHead>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row: any) => (
                  <TableRow
                    hover={!isMobile}
                    onClick={isMobile
                      ? () => onEditRowClick(row)
                      : undefined
                    }
                  >
                    <TableCell>
                      <Tooltip title={row[TABLE_COLUMNS.name].label}>
                        <Typography noWrap>
                          {row[TABLE_COLUMNS.name].label}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row[TABLE_COLUMNS.bankDetails].label}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row[TABLE_COLUMNS.registryForm].label}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row[TABLE_COLUMNS.invoiceForm].label}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div className={classes.cellContainer}>
                        <Tooltip
                          title={
                            row[TABLE_COLUMNS.taxTypesPaidByCompany].hint
                          }
                        >
                          <Chip
                            onClick={isMobile
                              ? (event) => event.stopPropagation()
                              : undefined}
                            label={row[TABLE_COLUMNS
                              .taxTypesPaidByCompany].label}
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.cellContainer}>
                        <Tooltip
                          title={
                            row[TABLE_COLUMNS
                              .taxTypesCompensatedByCompany].hint
                          }
                        >
                          <Chip
                            onClick={isMobile
                              ? (event) => event.stopPropagation()
                              : undefined}
                            label={row[TABLE_COLUMNS
                              .taxTypesCompensatedByCompany].label}
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.cellContainer}>
                        <Tooltip
                          title={(
                            <div className={classes.detailsTooltipContainer}>
                              <Typography
                                color="paper"
                                variant="caption"
                              >
                                {row[TABLE_COLUMNS.details].isRoundPayrollSum
                                  .hint}
                              </Typography>
                              <Typography
                                color="paper"
                                variant="caption"
                              >
                                {row[TABLE_COLUMNS.details]
                                  .shouldSendInvoiceInEmail.hint}
                              </Typography>
                              <Typography
                                color="paper"
                                variant="caption"
                              >
                                {row[TABLE_COLUMNS.details].showSumInUahInEmail
                                  .hint}
                              </Typography>
                              <Typography
                                color="paper"
                                variant="caption"
                              >
                                {row[TABLE_COLUMNS.details]
                                  .includeInPolishPaymentRegistry.hint}
                              </Typography>
                            </div>
                          )}
                        >
                          {!isMobile && (
                            <IconCheckMultiple size={ICON_SIZE_TITLE} />
                          )}
                          {isMobile && (
                            <IconButton
                              disableHoverSpace
                              onClick={(event) => event.stopPropagation()}
                            >
                              <IconCheckMultiple size={ICON_SIZE_TITLE} />
                            </IconButton>
                          )}
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.cellContainer}>
                        <Typography>
                          {row[TABLE_COLUMNS.active].label}
                        </Typography>
                      </div>
                    </TableCell>
                    {!isMobile && (
                      <TableCellActions>
                        <IconButton
                          disableHoverSpace
                          onClick={() => onEditRowClick(row)}
                        >
                          <IconEdit size={ICON_SIZE} />
                        </IconButton>
                        <IconButton
                          disableHoverSpace
                          onClick={() => setState({
                            ...state,
                            typeIdToDelete: row.typeId,
                          })}
                        >
                          <IconDelete size={ICON_SIZE} />
                        </IconButton>
                      </TableCellActions>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </>
      )}

      {/* Create/Update Dialog. Desktop */}
      <Dialog
        onClose={onCloseCreateUpdateDialog}
        open={(state.showCreateDialog || !!state.typeId) && !isMobile}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  { id: errorMessage ? 'error' : 'loading.error' },
                  { error: errorMessage }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card variant="edit">
          <CardTitle>
            <div>
              <Typography variant="title">
                {formatMessage({
                  id: state.typeId ? 'update.title' : 'create.title',
                })}
              </Typography>
              <Typography color="secondary">
                {formatMessage({
                  id: state.typeId ? 'update.subtitle' : 'create.subtitle',
                })}
              </Typography>
            </div>
            <IconButton onClick={onCloseCreateUpdateDialog}>
              <IconClose size={ICON_SIZE_TITLE} />
            </IconButton>
          </CardTitle>
          <div />
          <CardContent>
            <div className={classes.dialogContent}>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    <Colon>
                      <Mandatory>
                        {formatMessage({ id: 'typeName' })}
                      </Mandatory>
                    </Colon>
                  </Typography>
                </div>
                <TextField
                  AdornmentEnd={
                    state.validationErrors
                      .includes(errorTypes.EMPTY_FIELD_TITLE) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={formatMessage({
                          id: `validationError.${errorTypes.EMPTY_FIELD_TITLE}`,
                        })}
                      >
                        <IconError
                          color={theme.colors.redDark}
                          size={ICON_SIZE}
                        />
                      </Tooltip>
                    )
                  }
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  isError={state.validationErrors
                    .includes(errorTypes.EMPTY_FIELD_TITLE)}
                  onChange={({ target }) => onChangeTypeName(target.value)}
                  placeholder={formatMessage({
                    id: 'typeName.placeholder',
                  })}
                  size="small"
                  value={state.typeName}
                  variant="standard"
                />
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'bankDetails' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    bankDetails: target.value,
                  })}
                  size="small"
                  value={state.bankDetails}
                >
                  {bankDetailsTypes.map((type) => (
                    <MenuItem value={type}>
                      <Typography>
                        {formatMessage({ id: `bankDetails.${type}` })}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'registryForm' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    registryForm: target.value,
                  })}
                  size="small"
                  value={state.registryForm}
                >
                  <MenuItem value={null}>
                    <Typography>
                      {formatMessage({ id: 'NA' })}
                    </Typography>
                  </MenuItem>
                  {registryForms?.map((form: any) => (
                    <MenuItem value={form.id}>
                      <Typography>
                        {form.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'invoiceForm' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    invoiceForm: target.value,
                  })}
                  size="small"
                  value={state.invoiceForm}
                >
                  <MenuItem value={null}>
                    <Typography>
                      {formatMessage({ id: 'NA' })}
                    </Typography>
                  </MenuItem>
                  {invoiceForms?.map((form: any) => (
                    <MenuItem value={form.id}>
                      <Typography>
                        {form.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'paidTaxTypes' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate
                    || isFetchingUpdate
                    || !taxTypes?.length}
                  fullWidth
                  multiple
                  onChange={({ target }) => setState({
                    ...state,
                    taxTypeIdsPaidByCompany: target.value,
                  })}
                  renderValue={(types) => (
                    <Typography noWrap>
                      {formatTaxTypeNames(types, taxTypeMapping)}
                    </Typography>
                  )}
                  size="small"
                  value={state.taxTypeIdsPaidByCompany}
                >
                  {taxTypes?.map((type: any) => (
                    <MenuItem value={type.id}>
                      <Checkbox
                        disableHoverSpace
                        checked={state
                          .taxTypeIdsPaidByCompany.includes(type.id)}
                      />
                      <Typography>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'compensatedTaxTypes' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate
                    || isFetchingUpdate
                    || !taxTypes?.length}
                  fullWidth
                  multiple
                  onChange={({ target }) => setState({
                    ...state,
                    taxTypeIdsCompensatedByCompany: target.value,
                  })}
                  renderValue={(types) => (
                    <Typography noWrap>
                      {formatTaxTypeNames(types, taxTypeMapping)}
                    </Typography>
                  )}
                  size="small"
                  value={state.taxTypeIdsCompensatedByCompany}
                >
                  {taxTypes?.map((type: any) => (
                    <MenuItem value={type.id}>
                      <Checkbox
                        disableHoverSpace
                        checked={state
                          .taxTypeIdsCompensatedByCompany.includes(type.id)}
                      />
                      <Typography>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'roundPayroll' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    isRoundPayrollSum: target.value,
                  })}
                  size="small"
                  value={state.isRoundPayrollSum}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'sendInvoiceInEmail' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    shouldSendInvoiceInEmail: target.value,
                  })}
                  size="small"
                  value={state.shouldSendInvoiceInEmail}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'showSumInUahInEmail' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    showSumInUahInEmail: target.value,
                  })}
                  size="small"
                  value={state.showSumInUahInEmail}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'includeInPolishPaymentRegistry' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    includeInPolishPaymentRegistry: target.value,
                  })}
                  size="small"
                  value={state.includeInPolishPaymentRegistry}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'active' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    active: target.value,
                  })}
                  size="small"
                  value={state.active}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseCreateUpdateDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingCreate || isFetchingUpdate}
              onClick={onSaveContractType}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'save' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* Create/Update Dialog. Mobile */}
      <Dialog
        onClose={onCloseCreateUpdateDialog}
        open={(state.showCreateDialog || !!state.typeId) && isMobile}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  { id: errorMessage ? 'error' : 'loading.error' },
                  { error: errorMessage }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card variant="edit">
          <CardTitle>
            <Typography variant="title">
              {formatMessage({
                id: state.typeId ? 'update.title' : 'create.title',
              })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseCreateUpdateDialog}
            >
              <IconClose size={ICON_SIZE_TITLE} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <div className={classes.dialogContainerMobile}>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  <Colon>
                    <Mandatory>
                      {formatMessage({ id: 'typeName' })}
                    </Mandatory>
                  </Colon>
                </Typography>
                <TextField
                  AdornmentEnd={
                    state.validationErrors
                      .includes(errorTypes.EMPTY_FIELD_TITLE) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={formatMessage({
                          id: `validationError.${errorTypes.EMPTY_FIELD_TITLE}`,
                        })}
                      >
                        <IconError
                          color={theme.colors.redDark}
                          size={ICON_SIZE}
                        />
                      </Tooltip>
                    )
                  }
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  isError={state.validationErrors
                    .includes(errorTypes.EMPTY_FIELD_TITLE)}
                  onChange={({ target }) => onChangeTypeName(target.value)}
                  placeholder={formatMessage({
                    id: 'typeName.placeholder',
                  })}
                  size="small"
                  value={state.typeName}
                  variant="standard"
                />
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'bankDetails' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    bankDetails: target.value,
                  })}
                  size="small"
                  value={state.bankDetails}
                >
                  {bankDetailsTypes.map((type) => (
                    <MenuItem value={type}>
                      <Typography>
                        {formatMessage({ id: `bankDetails.${type}` })}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'registryForm' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    registryForm: target.value,
                  })}
                  size="small"
                  value={state.registryForm}
                >
                  <MenuItem value={null}>
                    <Typography>
                      {formatMessage({ id: 'NA' })}
                    </Typography>
                  </MenuItem>
                  {registryForms?.map((form: any) => (
                    <MenuItem value={form.id}>
                      <Typography>
                        {form.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'invoiceForm' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    invoiceForm: target.value,
                  })}
                  size="small"
                  value={state.invoiceForm}
                >
                  <MenuItem value={null}>
                    <Typography>
                      {formatMessage({ id: 'NA' })}
                    </Typography>
                  </MenuItem>
                  {invoiceForms?.map((form: any) => (
                    <MenuItem value={form.id}>
                      <Typography>
                        {form.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'paidTaxTypes' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate
                    || isFetchingUpdate
                    || !taxTypes?.length}
                  fullWidth
                  multiple
                  onChange={({ target }) => setState({
                    ...state,
                    taxTypeIdsPaidByCompany: target.value,
                  })}
                  renderValue={(types) => (
                    <Typography noWrap>
                      {formatTaxTypeNames(types, taxTypeMapping)}
                    </Typography>
                  )}
                  size="small"
                  value={state.taxTypeIdsPaidByCompany}
                >
                  {taxTypes?.map((type: any) => (
                    <MenuItem value={type.id}>
                      <Checkbox
                        disableHoverSpace
                        checked={state
                          .taxTypeIdsPaidByCompany.includes(type.id)}
                      />
                      <Typography>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'compensatedTaxTypes' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate
                    || isFetchingUpdate
                    || !taxTypes?.length}
                  fullWidth
                  multiple
                  onChange={({ target }) => setState({
                    ...state,
                    taxTypeIdsCompensatedByCompany: target.value,
                  })}
                  renderValue={(types) => (
                    <Typography noWrap>
                      {formatTaxTypeNames(types, taxTypeMapping)}
                    </Typography>
                  )}
                  size="small"
                  value={state.taxTypeIdsCompensatedByCompany}
                >
                  {taxTypes?.map((type: any) => (
                    <MenuItem value={type.id}>
                      <Checkbox
                        disableHoverSpace
                        checked={state
                          .taxTypeIdsCompensatedByCompany.includes(type.id)}
                      />
                      <Typography>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'roundPayroll' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    isRoundPayrollSum: target.value,
                  })}
                  size="small"
                  value={state.isRoundPayrollSum}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'sendInvoiceInEmail' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    shouldSendInvoiceInEmail: target.value,
                  })}
                  size="small"
                  value={state.shouldSendInvoiceInEmail}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'showSumInUahInEmail' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    showSumInUahInEmail: target.value,
                  })}
                  size="small"
                  value={state.showSumInUahInEmail}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'includeInPolishPaymentRegistry' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    includeInPolishPaymentRegistry: target.value,
                  })}
                  size="small"
                  value={state.includeInPolishPaymentRegistry}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'active' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    active: target.value,
                  })}
                  size="small"
                  value={state.active}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({ id: 'yes' })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({ id: 'no' })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </CardContent>
          <div />
          <CardActions>
            {state.typeId && (
              <IconButton
                disableHoverSpace
                onClick={() => setState({
                  ...state,
                  typeIdToDelete: state.typeId,
                })}
              >
                <IconDelete size={ICON_SIZE} />
              </IconButton>
            )}
            <Button
              onClick={onCloseCreateUpdateDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingCreate || isFetchingUpdate}
              onClick={onSaveContractType}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'save' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        onClose={onCloseDeleteDialog}
        open={!!state.typeIdToDelete}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  { id: errorMessage ? 'error' : 'loading.error' },
                  { error: errorMessage }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card>
          <CardTitle>
            <Typography variant="title">
              {formatMessage({ id: 'delete.title' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseDeleteDialog}
            >
              <IconClose size={ICON_SIZE_TITLE} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <Typography color="secondary">
              {formatMessage({ id: 'delete.subtitle' })}
            </Typography>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseDeleteDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingDelete}
              onClick={onDeleteContractType}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'delete' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => setState({
          ...state,
          showSuccessAfterSaveAlert: false,
        })}
        open={state.showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'loading.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
};

export default ContractTypes;
