import {
  CLEAR_SAVING_RESULTS,
  ERROR_RECEIVE_CURRENCIES,
  ERROR_UPDATE_CURRENCIES,
  FINISH_SAVE_CURRENCIES,
  RECEIVE_CURRENCIES,
  REQUEST_CURRENCIES,
  REQUEST_SAVE_CURRENCIES,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const errorReceiveCurrencies = () => ({
  type: ERROR_RECEIVE_CURRENCIES,
});

const receiveCurrencies = (currencies: any) => ({
  payload: currencies,
  type: RECEIVE_CURRENCIES,
});

const requestCurrencies = () => ({
  type: REQUEST_CURRENCIES,
});

const getCurrenciesPerYear = (year: any) => {
  const {
    BASE_URL,
    CURRENCY_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${CURRENCY_SERVICE}/period/${year}`)
    .then(({ data }) => data);
};

const errorUpdateCurrencies = () => ({
  type: ERROR_UPDATE_CURRENCIES,
});

const updateCurrencies = (
  currencies: any,
  month: any,
  year: any
) => {
  const {
    BASE_URL,
    CURRENCY_SERVICE,
  } = config;
  return axios
    .post(
      `${BASE_URL}${CURRENCY_SERVICE}/updateFromNbu/${year}/${month}`,
      currencies
    )
    .then(({ data }) => data);
};

const fetchUpdateCurrencies = (
  currencies: any,
  month: any,
  year: any
) => (dispatch: any) => {
  dispatch(requestCurrencies());
  return updateCurrencies(currencies, month, year)
    .then(() => dispatch(fetchCurrencies(year)))
    .catch(() => dispatch(errorUpdateCurrencies()));
};

const fetchCurrencies = (year: any) => (dispatch: any) => {
  dispatch(requestCurrencies());
  return getCurrenciesPerYear(year)
    .then(currencies => dispatch(receiveCurrencies(currencies)))
    .catch(() => dispatch(errorReceiveCurrencies()));
};

const requestSaveCurrencies = () => ({
  type: REQUEST_SAVE_CURRENCIES,
});

const finishSaveCurrencies = ({
  failedSaveItems,
  successSavedItems,
}: any) => ({
  payload: {
    failedSaveItems,
    successSavedItems,
  },
  type: FINISH_SAVE_CURRENCIES,
});

const bulkUpdateCurrencies = (list: any) => {
  const {
    BASE_URL,
    CURRENCY_SERVICE,
  } = config;

  return axios
    .post(
      `${BASE_URL}${CURRENCY_SERVICE}/bulkUpdate`,
      list
    )
    .then(({ data }) => data);
};

const fetchSaveCurrencies = ({
  saveList,
}: any) => (dispatch: any) => {
  dispatch(requestSaveCurrencies());
  return bulkUpdateCurrencies(saveList)
    .then(() => dispatch(finishSaveCurrencies({
      successSavedItems: saveList,
    })))
    .catch(() => dispatch(finishSaveCurrencies({
      failedSaveItems: saveList,
    })));
};

const clearSavingResults = () => (dispatch: any) => dispatch({
  type: CLEAR_SAVING_RESULTS,
});

const exportFunctions = {
  clearSavingResults,
  fetchCurrencies,
  fetchSaveCurrencies,
  fetchUpdateCurrencies,
};

export default exportFunctions;
