import React, { useState } from 'react';
import SelectMui from '@mui/material/Select';
import FormControlMui from '@mui/material/FormControl';
import InputLabelMui from '@mui/material/InputLabel';

const Select = ({
  children,
  disabled = false,
  disableUnderline = false,
  fullHeight = true,
  fullWidth = false,
  label,
  multiple = false,
  onChange,
  renderValue,
  size = 'medium',
  value,
  variant = 'standard',
}: IProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <FormControlMui
      focused={isFocused}
      fullWidth={fullWidth}
      size={size}
    >
      {label && (
        <InputLabelMui>
          {label}
        </InputLabelMui>
      )}
      <SelectMui
        disabled={disabled}
        disableUnderline={disableUnderline}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: fullHeight ? '100%' : '300px' },
          },
        }}
        multiple={multiple}
        onChange={onChange}
        onClose={() => setIsFocused(false)}
        onOpen={() => setIsFocused(true)}
        renderValue={renderValue}
        sx={{
          '.MuiSelect-select': {
            alignItems: 'center',
            display: 'flex',
          },
        }}
        value={value}
        variant={variant}
        label={label}
      >
        {children}
      </SelectMui>
    </FormControlMui>
  );
};

interface IProps {
  children: React.ReactNode,
  disabled?: boolean,
  disableUnderline?: boolean,
  fullHeight?: boolean,
  fullWidth?: boolean,
  label?: string,
  multiple?: boolean,
  onChange: (event: any) => void,
  renderValue?: (value: any) => React.ReactNode,
  size?: 'medium' | 'small',
  value: any,
  variant?: 'standard' | 'outlined',
}

export default Select;
