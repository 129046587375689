import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardTitle from 'components/CardTitle';
import CircularProgress from 'components/CircularProgress';
import HighlightText from 'components/HighlightText';
import IconClose from 'components/icons/Close';
import IconPlus from 'components/icons/Plus';
import IconRefresh from 'components/icons/Refresh';
import IconSearch from 'components/icons/Search';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import Loading from 'components/Loading';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TablePagination from 'components/TablePagination';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useChangePage from 'hooks/useChangePage';
import useDebounce from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import actionsContractors from '../actions/contractors';
import actionsContractTypes from '../actions/contractTypes';
import actionsWorkspaces from '../actions/workspaces';
import actionsSetup from '../actions/setup';
import paginationSizesList, * as paginationSizes
  from '../constants/paginationSizes';
import contractSources, { CLOCKIFY } from '../constants/contractSources';
import statuses from '../constants/statusTypes';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionsContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  actionsInnerContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(2)}px`,
  },
  actionsInnerContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
    justifyContent: 'space-between',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  selectContractType: {
    width: '175px',
  },
  selectSource: {
    width: '125px',
  },
  selectWorkspaces: {
    width: '110px',
  },
}));

const ALL = 'all';
const ICON_SIZE = 24;

const TABLE_COLUMNS = {
  documents: 'DOCUMENTS',
  email: 'EMAIL',
  name: 'NAME',
  phone: 'PHONE',
};

const formatLink = (href: string) => (href.startsWith('http'))
  ? href
  : `https://${href}`;

function Contractors() {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const dispatch: Dispatch<any> = useDispatch();
  const changePage = useChangePage();
  const isMobile = useIsMobile();
  const locationSearch = useLocationSearch();
  const contractTypeId = locationSearch.contractTypeId || ALL;
  const pageIndex = +locationSearch.pageIndex || 0;
  const pageSize = +locationSearch.pageSize || paginationSizes.SMALL;
  const status = locationSearch.status || statuses.ACTIVE;
  const source = locationSearch.source || ALL;
  const workspace = locationSearch.workspace || ALL;

  const {
    isFailed: isFailedContractors,
    isFailedRefresh,
    isFetching: isFetchingContractors,
    isFetchingRefresh,
    isSuccessRefresh,
    list: contractors,
    totalCount: contractorsTotalCount,
  } = useSelector(({
    contractors: reducerContractors,
  }: any) => reducerContractors);

  const {
    isFailed: isFailedContractTypes,
    isFetching: isFetchingContractTypes,
    isFinished: isFinishedContractTypes,
    list: contractTypes,
  } = useSelector(({
    contractTypes: reducerContractTypes,
  }: any) => reducerContractTypes);

  const {
    isFailed: isFailedWorkspaces,
    isFetching: isFetchingWorkspaces,
    isFinished: isFinishedWorkspaces,
    list: workspaces,
  } = useSelector(({
    workspaces: reducerWorkspaces,
  }: any) => reducerWorkspaces);

  const [state, setState] = useState({
    compactColumnIndex: 1,
    searchText: '',
    showErrorAfterSaveAlert: false,
    showSuccessAfterSaveAlert: false,
  });

  const debouncedSearchText = useDebounce({ value: state.searchText });
  const isFetching = isFetchingContractors || isFetchingRefresh
    || isFetchingContractTypes || isFetchingWorkspaces;

  const tableRows = useMemo(() => (contractors
    .map((contractor: any) => ({
      contractorId: contractor.id,
      [TABLE_COLUMNS.name]: { value: contractor.name },
      [TABLE_COLUMNS.email]: { value: contractor.email },
      [TABLE_COLUMNS.phone]: { value: contractor.phone },
      [TABLE_COLUMNS.documents]: {
        value: contractor.documentsLink
          ? formatLink(contractor.documentsLink)
          : '',
      },
    }))
  ), [contractors]);

  const getValidContractTypeId = (contractTypeId: string) =>
    contractTypes.find(({ id }: { id: any }) =>
      id === contractTypeId)?.id || null;

  const getWorkspaceId = (workspace: string) =>
    workspaces === ALL ? null : workspaces
      .find(({ name }: any) => name === workspace)?.id;

  const handleRefreshContractors = () => {
    dispatch(actionsContractors.fetchRefreshContractors({
      contractTypeId: getValidContractTypeId(contractTypeId),
      pageIndex,
      pageSize,
      searchText: debouncedSearchText,
      source: source === ALL ? null : source,
      status,
      workspace : getWorkspaceId(workspace),
    }));
    if (pageIndex !== 0) {
      changePage({
        locationSearch: {
          ...locationSearch,
          pageIndex: 0,
        },
        replace: true,
      });
    }
    setState({
      ...state,
      searchText: '',
    });
  };

  const handleChangeStatus = (status: string) => {
    changePage({
      locationSearch: {
        ...locationSearch,
        pageIndex: 0,
        status,
      },
      replace: true,
    });
  };

  const handleChangeContractType = (contractTypeId: string) => {
    changePage({
      locationSearch: {
        ...locationSearch,
        contractTypeId,
        pageIndex: 0,
      },
      replace: true,
    });
  };

  const handleChangeSource = (source: string) => {
    changePage({
      locationSearch: {
        ...locationSearch,
        pageIndex: 0,
        source,
        workspace: source === CLOCKIFY ? workspace : ALL,
      },
      replace: true,
    });
  };

  const handleChangeWorkspace = (workspace: string) => {
    changePage({
      locationSearch: {
        ...locationSearch,
        pageIndex: 0,
        workspace,
      },
      replace: true,
    });
  };

  const handleChangeSearch = (searchText: string) => {
    if (pageIndex !== 0) {
      changePage({
        locationSearch: {
          ...locationSearch,
          pageIndex: 0,
        },
        replace: true,
      });
    }
    setState({
      ...state,
      searchText,
    });
  };

  useEffect(() => setState({
    ...state,
    showErrorAfterSaveAlert: isFailedRefresh,
    showSuccessAfterSaveAlert: isSuccessRefresh,
  }), [isFetchingRefresh]);

  useEffect(() => {
    if (isFinishedContractTypes) {
      dispatch(actionsContractors.fetchContractors({
        contractTypeId: getValidContractTypeId(contractTypeId),
        pageIndex,
        pageSize,
        searchText: debouncedSearchText,
        source: source === ALL ? null : source,
        status,
        workspace : getWorkspaceId(workspace),
      }));
    }
  }, [
    contractTypeId,
    debouncedSearchText,
    isFinishedContractTypes,
    pageIndex,
    pageSize,
    source,
    status,
    workspace,
  ]);

  useEffect(() => {
    dispatch(actionsContractTypes.fetchContractTypes(true));
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  useEffect(() => {
    dispatch(actionsWorkspaces.fetchWorkspaces());
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.actionsContainer}>
        <Breadcrumbs>
          <Breadcrumb
            label={formatMessage({ id: 'contractors' })}
            variant="text"
          />
        </Breadcrumbs>
        {isMobile && (
          <Tooltip title={formatMessage({ id: 'refresh' })}>
            <IconButton
              disabled={isFetching}
              onClick={handleRefreshContractors}
            >
              {isFetching
                ? <CircularProgress size={ICON_SIZE} />
                : <IconRefresh size={ICON_SIZE} />
              }
            </IconButton>
          </Tooltip>
        )}
      </div>
      {!isMobile && (
        <div className={classes.actionsContainer}>
          {isFinishedContractTypes && (
            <div className={classes.actionsInnerContainer}>
              <Select
                disabled={isFetching}
                label={formatMessage({ id: 'contractor.status' })}
                onChange={({ target }) => handleChangeStatus(target.value)}
                size="small"
                value={status}
                variant="outlined"
              >
                {Object
                  .keys(statuses)
                  .map(status => (
                    <MenuItem value={status}>
                      <Typography>
                        {formatMessage({ id: `status.${status}` })}
                      </Typography>
                    </MenuItem>
                  ))}
              </Select>
              <div className={classes.selectContractType}>
                <Select
                  disabled={isFetching}
                  fullWidth
                  label={formatMessage({ id: 'contractor.contractTypes' })}
                  onChange={({ target }) =>
                    handleChangeContractType(target.value)}
                  size="small"
                  value={contractTypeId}
                  variant="outlined"
                >
                  <MenuItem value={ALL}>
                    <Typography>
                      {formatMessage({ id: 'all' })}
                    </Typography>
                  </MenuItem>
                  {contractTypes.map((type: any) => (
                    <MenuItem value={type.id}>
                      <Typography noWrap>
                        {type.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.selectSource}>
                <Select
                  disabled={isFetching}
                  fullWidth
                  label={formatMessage({ id: 'contractor.sources' })}
                  onChange={({ target }) => handleChangeSource(target.value)}
                  size="small"
                  value={source}
                  variant="outlined"
                >
                  <MenuItem value={ALL}>
                    <Typography>
                      {formatMessage({ id: 'all' })}
                    </Typography>
                  </MenuItem>
                  {contractSources.map((source) => (
                    <MenuItem
                      key={source}
                      value={source}
                    >
                      <Typography>
                        {formatMessage({ id: `source.${source}` })}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </div>
              {(isFinishedWorkspaces && source === CLOCKIFY) && (
                <div className={classes.selectWorkspaces}>
                  <Select
                    disabled={isFetching}
                    fullWidth
                    label={formatMessage({ id: 'contractor.workspaces' })}
                    onChange={({ target }) =>
                      handleChangeWorkspace(target.value)}
                    size="small"
                    value={workspace || ALL}
                    variant="outlined"
                  >
                    <MenuItem value={ALL}>
                      <Typography>
                        {formatMessage({ id: 'all' })}
                      </Typography>
                    </MenuItem>
                    {workspaces.map((workspace: any) => (
                      <MenuItem value={workspace.name}>
                        <Typography>
                          {workspace.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <TextField
                AdornmentStart={<IconSearch size={ICON_SIZE} />}
                disabled={isFetching}
                onChange={({ target }) => handleChangeSearch(target.value)}
                placeholder={formatMessage({ id: 'search' })}
                size="small"
                value={state.searchText}
              />
            </div>
          )}
          {!isFinishedContractTypes && (
            <CircularProgress size={ICON_SIZE} />
          )}
          <Tooltip title={formatMessage({ id: 'refresh' })}>
            <IconButton
              disabled={isFetching}
              onClick={handleRefreshContractors}
            >
              {isFetching
                ? <CircularProgress size={ICON_SIZE} />
                : <IconRefresh size={ICON_SIZE} />
              }
            </IconButton>
          </Tooltip>
        </div>
      )}
      {isMobile && (
        <div className={classes.actionsContainerMobile}>
          <div className={classes.actionsInnerContainerMobile}>
            <Select
              disabled={isFetching}
              fullWidth
              label={formatMessage({ id: 'contractor.status' })}
              onChange={({ target }) => handleChangeStatus(target.value)}
              size="small"
              value={status}
              variant="outlined"
            >
              {Object
                .keys(statuses)
                .map(status => (
                  <MenuItem value={status}>
                    <Typography>
                      {formatMessage({ id: `status.${status}` })}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
            <Select
              disabled={isFetching}
              fullWidth
              label={formatMessage({ id: 'contractor.contractTypes' })}
              onChange={({ target }) => handleChangeContractType(target.value)}
              size="small"
              value={contractTypeId}
              variant="outlined"
            >
              <MenuItem value={ALL}>
                <Typography>
                  {formatMessage({ id: 'all' })}
                </Typography>
              </MenuItem>
              {contractTypes.map((type: any) => (
                <MenuItem value={type.id}>
                  <Typography noWrap>
                    {type.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.actionsInnerContainerMobile}>
            <Select
              disabled={isFetching}
              fullWidth
              label={formatMessage({ id: 'contractor.sources' })}
              onChange={({ target }) => handleChangeSource(target.value)}
              size="small"
              value={source}
              variant="outlined"
            >
              <MenuItem value={ALL}>
                <Typography>
                  {formatMessage({ id: 'all' })}
                </Typography>
              </MenuItem>
              {contractSources.map((source) => (
                <MenuItem
                  key={source}
                  value={source}
                >
                  <Typography>
                    {formatMessage({ id: `source.${source}` })}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {(isFinishedWorkspaces && source === CLOCKIFY) && (
              <Select
                disabled={isFetching}
                fullWidth
                label={formatMessage({ id: 'contractor.workspaces' })}
                onChange={({ target }) => handleChangeWorkspace(target.value)}
                size="small"
                value={workspace || ALL}
                variant="outlined"
              >
                <MenuItem value={ALL}>
                  <Typography>
                    {formatMessage({ id: 'all' })}
                  </Typography>
                </MenuItem>
                {workspaces.map((workspace: any) => (
                  <MenuItem value={workspace.name}>
                    <Typography>
                      {workspace.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          <TextField
            AdornmentStart={<IconSearch size={ICON_SIZE} />}
            disabled={isFetching}
            onChange={({ target }) => handleChangeSearch(target.value)}
            placeholder={formatMessage({ id: 'search' })}
            size="small"
            value={state.searchText}
          />
        </div>
      )}
      <Card>
        <CardTitle>
          <Typography color="secondary">
            {formatMessage({ id: 'create.subtitle' })}
          </Typography>
          <Button
            onClick={() => changePage({
              pathname: 'details',
            })}
            variant="primary"
            startIcon={(
              <IconPlus
                color="button"
                size={20}
              />
            )}
          >
            <Typography color="inherit">
              {formatMessage({ id: 'add' })}
            </Typography>
          </Button>
        </CardTitle>
      </Card>
      <div>
        {/* List */}
        {(isFetchingContractors || isFailedContractors
          || isFetchingWorkspaces || isFailedWorkspaces
          || isFetchingContractTypes || isFailedContractTypes)
          && !contractors.length
          && (
            <Loading variant={isFailedContractors ? 'error' : 'loading'}>
              {isFailedContractors && (
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'loading.error' })}
                </Typography>
              )}
            </Loading>
          )}
        {(!isFetchingContractors
            && !isFailedContractors
            && isFinishedContractTypes
            && isFinishedWorkspaces)
          && !contractors.length
          && (
            <Loading variant="noData">
              <Typography
                color="secondary"
                variant="subtitle"
              >
                {formatMessage({ id: 'loading.noData' })}
              </Typography>
            </Loading>
          )}
        {!!contractors.length && (
          <Card disablePaddings>
            {(isFetchingContractors || isFailedContractors )
              && (
                <Loading variant={isFailedContractors ? 'error' : 'loading'}>
                  {isFailedContractors && (
                    <Typography color="secondary" variant="subtitle">
                      {formatMessage({ id: 'loading.error' })}
                    </Typography>
                  )}
                </Loading>
              )}
            {!isFetchingContractors && !isFailedContractors && (
              <Table
                compactColumnIndex={state.compactColumnIndex}
                compact={isMobile}
                fixed
                onChangeCompactColumnIndex={(index: number) => setState({
                  ...state,
                  compactColumnIndex: index,
                })}
              >
                <TableHead>
                  <TableRow>
                    <TableCellHead
                      compactVariant="static"
                    >
                      <Typography
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.name}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.email}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.phone}`,
                        })}
                      </Typography>
                    </TableCellHead>
                    <TableCellHead
                      compactVariant="dynamic"
                    >
                      <Typography
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.documents}`,
                        })}
                      </Typography>
                    </TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row: any) => (
                    <TableRow
                      hover
                      onClick={() => changePage({
                        pathname: `details/${row.contractorId}`,
                      })}
                    >
                      <TableCell>
                        <HighlightText search={debouncedSearchText}>
                          <Typography>
                            {row[TABLE_COLUMNS.name].value}
                          </Typography>
                        </HighlightText>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap>
                          {row[TABLE_COLUMNS.email].value}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {row[TABLE_COLUMNS.phone].value}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Link
                          onClick={event => event.stopPropagation()}
                          underline
                          href={row[TABLE_COLUMNS.documents].value}
                          target="_blank"
                        >
                          <Typography noWrap>
                            {row[TABLE_COLUMNS.documents].value}
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            <TablePagination
              count={contractorsTotalCount}
              labelDisplayedRows={({ count, from, to }) => formatMessage(
                { id: 'pagination.of' },
                {
                  count: count !== -1
                    ? count
                    : formatMessage(
                      { id: 'pagination.moreThan' },
                      { to }
                    ),
                  from,
                  to,
                }
              )}
              labelRowsPerPage={(
                <Typography
                  color="secondary"
                  variant="caption"
                >
                  {formatMessage({
                    id: isMobile
                      ? 'contractorsPerPage.short'
                      : 'contractorsPerPage',
                  })}
                </Typography>
              )}
              onPageChange={(event, pageIndex) => changePage({
                locationSearch: {
                  ...locationSearch,
                  pageIndex,
                },
                replace: true,
              })}
              onRowsPerPageChange={({ target }) => changePage({
                locationSearch: {
                  ...locationSearch,
                  pageIndex: 0,
                  pageSize: target.value,
                },
                replace: true,
              })}
              page={pageIndex}
              rowsPerPage={pageSize}
              rowsPerPageOptions={paginationSizesList}
            />
          </Card>
        )}
      </div>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => setState({
          ...state,
          showSuccessAfterSaveAlert: false,
        })}
        open={state.showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'loading.successRefresh' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
      <Snackbar open={state.showErrorAfterSaveAlert}>
        <Card variant="error">
          <CardTitle>
            <Typography color="error">
              {formatMessage({ id: 'loading.errorRefresh' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={() => setState({
                ...state,
                showErrorAfterSaveAlert: false,
              })}
            >
              <IconClose size={ICON_SIZE} />
            </IconButton>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
}

export default Contractors;
