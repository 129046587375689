import { combineReducers } from 'redux';

import contractors from './contractors';
import contractTypes from './contractTypes';
import workspaces from './workspaces';

const rootReducer = combineReducers({
  contractTypes,
  contractors,
  workspaces,
});

export default rootReducer;
