import {
  ERROR_CREATE_CONTRACTOR,
  ERROR_RECEIVE_CONTRACTOR,
  ERROR_UPDATE_CONTRACTOR,
  RECEIVE_CONTRACTOR,
  REQUEST_CONTRACTOR,
  REQUEST_CREATE_CONTRACTOR,
  REQUEST_UPDATE_CONTRACTOR,
  SUCCESS_CREATE_CONTRACTOR,
  SUCCESS_UPDATE_CONTRACTOR,
} from '../constants/actionTypes';

import axios from 'utils/requests';
import config from 'config';

const requestContractor = () => ({
  type: REQUEST_CONTRACTOR,
});

const receiveContractor = (response: any) => ({
  payload: response,
  type: RECEIVE_CONTRACTOR,
});

const errorReceiveContractor = () => ({
  type: ERROR_RECEIVE_CONTRACTOR,
});

const getContractor = (contractorId: string) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.get(`${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}`)
    .then(({ data }) => data);
};

const fetchContractor = (contractorId: any) => (dispatch: any) => {
  dispatch(requestContractor());
  return getContractor(contractorId)
    .then(contractor => dispatch(receiveContractor(contractor)))
    .catch(() => dispatch(errorReceiveContractor()));
};

const requestUpdateContractor = () => ({
  type: REQUEST_UPDATE_CONTRACTOR,
});

const successUpdateContractor = (response: any) => ({
  payload: response,
  type: SUCCESS_UPDATE_CONTRACTOR,
});

const errorUpdateContractor = (response: any) => ({
  payload: response,
  type: ERROR_UPDATE_CONTRACTOR,
});

const requestCreateContractor = () => ({
  type: REQUEST_CREATE_CONTRACTOR,
});

const successCreateContractor = (response: any) => ({
  payload: response,
  type: SUCCESS_CREATE_CONTRACTOR,
});

const errorCreateContractor = (response: any) => ({
  payload: response,
  type: ERROR_CREATE_CONTRACTOR,
});

const updateContractor = ({
  address,
  addressPol,
  bankAddress,
  bankAddressEur,
  bankUkrFop,
  bankUkrFo,
  bankUsd,
  bankEur,
  contractorId,
  correspondentBank,
  correspondentBankEur,
  correspondentBankSwift,
  correspondentBankSwiftEur,
  contractTypeId,
  documentsLink,
  email,
  emailPersonal,
  fullName,
  fullNameUkr,
  ibanFop,
  ibanFo,
  ibanUsd,
  ibanEur,
  inn,
  phone,
  source,
  status,
  swift,
  swiftEur,
  taxGroup,
}: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.put(`${BASE_URL}${CONTRACTORS_SERVICE}/${contractorId}`, {
    address,
    addressPol,
    bankFo: bankUkrFo,
    bankFop: bankUkrFop,
    contractTypeId,
    documentsLink,
    email,
    emailPersonal,
    eurForeignBankDetails: {
      bankAddress: bankAddressEur,
      bankName: bankEur,
      bankSwiftCode: swiftEur,
      correspondentBank: correspondentBankEur,
      correspondentBankSwiftCode: correspondentBankSwiftEur,
      iban: ibanEur,
    },
    foreignBankDetails: {
      bankAddress,
      bankName: bankUsd,
      bankSwiftCode: swift,
      correspondentBank,
      correspondentBankSwiftCode: correspondentBankSwift,
      iban: ibanUsd,
    },
    fullName,
    fullNameUkr,
    ibanFo,
    ibanFop,
    phone,
    source,
    status,
    taxGroup,
    taxId: inn,
  }).then(({ data }) => data);
};

const fetchUpdateContractor = ({
  address,
  addressPol,
  bankAddress,
  bankAddressEur,
  bankUkrFop,
  bankUkrFo,
  bankUsd,
  bankEur,
  contractorId,
  correspondentBank,
  correspondentBankEur,
  correspondentBankSwift,
  correspondentBankSwiftEur,
  contractTypeId,
  documentsLink,
  email,
  emailPersonal,
  fullName,
  fullNameUkr,
  ibanFop,
  ibanFo,
  ibanUsd,
  ibanEur,
  inn,
  phone,
  source,
  status,
  swift,
  swiftEur,
  taxGroup,
}: any) => (dispatch: any) => {
  dispatch(requestUpdateContractor());
  return updateContractor({
    address,
    addressPol,
    bankAddress,
    bankAddressEur,
    bankEur,
    bankUkrFo,
    bankUkrFop,
    bankUsd,
    contractTypeId,
    contractorId,
    correspondentBank,
    correspondentBankEur,
    correspondentBankSwift,
    correspondentBankSwiftEur,
    documentsLink,
    email,
    emailPersonal,
    fullName,
    fullNameUkr,
    ibanEur,
    ibanFo,
    ibanFop,
    ibanUsd,
    inn,
    phone,
    source,
    status,
    swift,
    swiftEur,
    taxGroup,
  }).then(contractor => dispatch(successUpdateContractor(contractor)))
    .catch((error) => dispatch(errorUpdateContractor(error)));
};

const createContractor = ({
  address,
  addressPol,
  bankAddress,
  bankAddressEur,
  bankUkrFop,
  bankUkrFo,
  bankUsd,
  bankEur,
  contractTypeId,
  correspondentBank,
  correspondentBankEur,
  correspondentBankSwift,
  correspondentBankSwiftEur,
  documentsLink,
  email,
  emailPersonal,
  fullName,
  fullNameUkr,
  ibanFop,
  ibanFo,
  ibanUsd,
  ibanEur,
  inn,
  phone,
  source,
  status,
  swift,
  swiftEur,
  taxGroup,
}: any) => {
  const {
    BASE_URL,
    CONTRACTORS_SERVICE,
  } = config;

  return axios.post(`${BASE_URL}${CONTRACTORS_SERVICE}`, {
    address,
    addressPol,
    bankFo: bankUkrFo,
    bankFop: bankUkrFop,
    contractTypeId,
    documentsLink,
    email,
    emailPersonal,
    eurForeignBankDetails: {
      bankAddress: bankAddressEur,
      bankName: bankEur,
      bankSwiftCode: swiftEur,
      correspondentBank: correspondentBankEur,
      correspondentBankSwiftCodeEur: correspondentBankSwiftEur,
      iban: ibanEur,
    },
    foreignBankDetails: {
      bankAddress,
      bankName: bankUsd,
      bankSwiftCode: swift,
      correspondentBank,
      correspondentBankSwiftCode: correspondentBankSwift,
      iban: ibanUsd,
    },
    fullName,
    fullNameUkr,
    ibanFo,
    ibanFop,
    phone,
    source,
    status,
    taxGroup,
    taxId: inn,
  }).then(({ data }) => data);
};

const fetchCreateContractor = ({
  address,
  addressPol,
  bankAddress,
  bankAddressEur,
  bankUkrFop,
  bankUkrFo,
  bankUsd,
  bankEur,
  correspondentBank,
  correspondentBankEur,
  correspondentBankSwift,
  correspondentBankSwiftEur,
  contractTypeId,
  documentsLink,
  email,
  emailPersonal,
  fullName,
  fullNameUkr,
  ibanFop,
  ibanFo,
  ibanUsd,
  ibanEur,
  inn,
  phone,
  source,
  status,
  swift,
  swiftEur,
  taxGroup,
}: any) => (dispatch: any) => {
  dispatch(requestCreateContractor());
  return createContractor({
    address,
    addressPol,
    bankAddress,
    bankAddressEur,
    bankEur,
    bankUkrFo,
    bankUkrFop,
    bankUsd,
    contractTypeId,
    correspondentBank,
    correspondentBankEur,
    correspondentBankSwift,
    correspondentBankSwiftEur,
    documentsLink,
    email,
    emailPersonal,
    fullName,
    fullNameUkr,
    ibanEur,
    ibanFo,
    ibanFop,
    ibanUsd,
    inn,
    phone,
    source,
    status,
    swift,
    swiftEur,
    taxGroup,
  }).then(contractor => dispatch(successCreateContractor(contractor)))
    .catch((error) => dispatch(errorCreateContractor(error)));
};

const exportFunctions = {
  fetchContractor,
  fetchCreateContractor,
  fetchUpdateContractor,
};

export default exportFunctions;
