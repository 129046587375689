import {
  ERROR_DOWNLOAD_INVOICE,
  ERROR_DOWNLOAD_REGISTRY,
  ERROR_SEND_CONFIRMED_INVOICES,
  ERROR_SEND_INVOICE,
  REQUEST_DOWNLOAD_INVOICE,
  REQUEST_DOWNLOAD_REGISTRY,
  REQUEST_SEND_CONFIRMED_INVOICES,
  REQUEST_SEND_INVOICE,
  SUCCESS_DOWNLOAD_INVOICE,
  SUCCESS_DOWNLOAD_REGISTRY,
  SUCCESS_SEND_CONFIRMED_INVOICES,
  SUCCESS_SEND_INVOICE,
} from '../constants/actionTypes';

import axios from 'axios';
import config from 'config';
import { extractFileName } from 'utils/headers';
import fileSaver from 'file-saver';

const requestDownloadInvoice = () => ({
  type: REQUEST_DOWNLOAD_INVOICE,
});

const successDownloadInvoice = () => ({
  type: SUCCESS_DOWNLOAD_INVOICE,
});

const errorDownloadInvoice = (message: any) => ({
  payload: message,
  type: ERROR_DOWNLOAD_INVOICE,
});

const downloadInvoice = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .get(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/invoice`, {
      responseType: 'blob',
    })
    .then(response => ({
      invoice: response.data,
      title: extractFileName(response.headers['content-disposition'])
        || 'invoice.docx',
    }))
    .catch((error) => error.response?.data?.text()
      .then((text : any) => Promise.reject(JSON.parse(text))));
};

const fetchDownloadInvoice = ({
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestDownloadInvoice());
  return downloadInvoice({ payrollId })
    .then(({ invoice, title }) => {
      fileSaver.saveAs(invoice, title);
      return dispatch(successDownloadInvoice());
    })
    .catch((error) => dispatch(errorDownloadInvoice(error)));
};

const requestDownloadRegistry = () => ({
  type: REQUEST_DOWNLOAD_REGISTRY,
});

const successDownloadRegistry = () => ({
  type: SUCCESS_DOWNLOAD_REGISTRY,
});

const errorDownloadRegistry = (message: any) => ({
  payload: message,
  type: ERROR_DOWNLOAD_REGISTRY,
});

const downloadPaymentRegistry = ({
  contractTypeId,
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${PAYROLLS_SERVICE}/registry`,
      {
        contractTypeId,
        month,
        year,
      },
      {
        responseType: 'blob',
      })
    .then(response => ({
      registry: response.data,
      title: extractFileName(response.headers['content-disposition'])
        || 'paymentRegistry.xlsx',
    }))
    .catch((error) => error.response?.data?.text()
      .then((text: any) => Promise.reject(JSON.parse(text))));
};

const fetchDownloadRegistry = ({
  contractTypeId,
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestDownloadRegistry());
  return downloadPaymentRegistry({
    contractTypeId,
    month,
    year,
  })
    .then(({ registry, title }) => {
      fileSaver.saveAs(registry, title);
      return dispatch(successDownloadRegistry());
    })
    .catch((error) =>
      dispatch(errorDownloadRegistry(error)));
};

const requestSendInvoice = () => ({
  type: REQUEST_SEND_INVOICE,
});

const successSendInvoice = () => ({
  type: SUCCESS_SEND_INVOICE,
});

const errorSendInvoice = (message: string) => ({
  message: message,
  type: ERROR_SEND_INVOICE,
});

const sendInvoice = ({
  payrollId,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;

  return axios
    .post(`${BASE_URL}${PAYROLLS_SERVICE}/${payrollId}/invoice/send`);
};

const fetchSendInvoice = ({
  payrollId,
}: any) => (dispatch: any) => {
  dispatch(requestSendInvoice());
  return sendInvoice({
    payrollId,
  })
    .then(() => dispatch(successSendInvoice()))
    .catch((error) =>
      dispatch(errorSendInvoice(error.response?.data?.message)));
};

const requestSendConfirmedInvoices = () => ({
  type: REQUEST_SEND_CONFIRMED_INVOICES,
});

const successSendConfirmedInvoices = () => ({
  type: SUCCESS_SEND_CONFIRMED_INVOICES,
});

const errorSendConfirmedInvoices = (message: string) => ({
  payload: message,
  type: ERROR_SEND_CONFIRMED_INVOICES,
});

const sendConfirmedInvoices = ({
  contractTypeId,
  month,
  year,
}: any) => {
  const {
    BASE_URL,
    PAYROLLS_SERVICE,
  } = config;
  return axios.post(`${BASE_URL}${PAYROLLS_SERVICE}/sendAll`,
    {
      contractTypeId,
      month: month,
      year: year,
    }).then(({ data }) => data);
};

const fetchSendConfirmedInvoices = ({
  contractTypeId,
  month,
  year,
}: any) => (dispatch: any) => {
  dispatch(requestSendConfirmedInvoices());
  return sendConfirmedInvoices({
    contractTypeId,
    month,
    year,
  })
    .then(() => dispatch(successSendConfirmedInvoices()))
    .catch((error: any) =>
      dispatch(errorSendConfirmedInvoices(
        error.response?.data?.message)));
};

const exportFunctions = {
  fetchDownloadInvoice,
  fetchDownloadRegistry,
  fetchSendConfirmedInvoices,
  fetchSendInvoice,
};

export default exportFunctions;
