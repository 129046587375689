import {
  CLEAR_SAVING_RESULTS,
  ERROR_RECEIVE_CURRENCIES,
  ERROR_UPDATE_CURRENCIES,
  FINISH_SAVE_CURRENCIES,
  RECEIVE_CURRENCIES,
  REQUEST_CURRENCIES,
  REQUEST_SAVE_CURRENCIES,
} from '../constants/actionTypes';

type Action = {
  payload: any,
  type: string,
}

const initialState = {
  failedSaveItems: [],
  isFailed: false,
  isFetching: false,
  isFetchingSave: false,
  list: null,
  successSavedItems: [],
};

const convertCurrency = (currency: any) => ({
  currency: currency.currency,
  month: currency.month,
  value: currency.rateToUah,
  year: currency.year,
});

const convertCurrencies = (input: any) => input
  .map((currency: any) => convertCurrency(currency));

const convertAfterSaveCurrencies = (input: any) => input
  .reduce((acc: any, item: any) => {
    const partAcc = item.saveRates.map((rate: any) => ({
      currency: item.currency,
      month: rate.month,
      value: rate.rateToUah,
      year: item.year,
    }));
    return acc.concat(partAcc);
  }, []);

export default function Reducer(state = initialState, action: Action) {
  switch (action.type) {
    case CLEAR_SAVING_RESULTS: {
      return {
        ...state,
        failedSaveItems: initialState.failedSaveItems,
        successSavedItems: initialState.successSavedItems,
      };
    }
    case ERROR_RECEIVE_CURRENCIES: {
      return {
        ...state,
        isFailed: true,
        isFetching: true,
      };
    }
    case ERROR_UPDATE_CURRENCIES: {
      return {
        ...state,
        isFailed: true,
        isFetching: false,
      };
    }

    case REQUEST_CURRENCIES: {
      return {
        ...state,
        failedSaveItems: initialState.failedSaveItems,
        isFailed: false,
        isFetching: true,
        list: null,
        successSavedItems: initialState.successSavedItems,
      };
    }
    case RECEIVE_CURRENCIES: {
      const list = convertCurrencies(action.payload);
      return {
        ...state,
        isFailed: false,
        isFetching: false,
        list,
      };
    }

    case REQUEST_SAVE_CURRENCIES: {
      return {
        ...state,
        failedSaveItems: initialState.failedSaveItems,
        isFetchingSave: true,
        successSavedItems: initialState.successSavedItems,
      };
    }
    case FINISH_SAVE_CURRENCIES: {
      return {
        ...state,
        failedSaveItems: action.payload.failedSaveItems
          ? convertAfterSaveCurrencies(action.payload.failedSaveItems)
          : initialState.failedSaveItems,
        isFetchingSave: false,
        successSavedItems: action.payload.successSavedItems
          ? convertAfterSaveCurrencies(action.payload.successSavedItems)
          : initialState.successSavedItems,
      };
    }

    default: return state;
  }
};
