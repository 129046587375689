import React, { useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import Breadcrumb from 'components/Breadcrumb';
import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import Card from 'components/Card';
import CardActions from 'components/CardActions';
import CardContent from 'components/CardContent';
import CardTitle from 'components/CardTitle';
import Colon from 'components/Colon';
import Dialog from 'components/Dialog';
import IconButton from 'components/IconButton';
import IconClose from 'components/icons/Close';
import IconDelete from 'components/icons/Delete';
import IconEdit from 'components/icons/Edit';
import IconError from 'components/icons/Error';
import IconPlus from 'components/icons/Plus';
import Loading from 'components/Loading';
import Mandatory from 'components/Mandatory';
import MenuItem from 'components/MenuItem';
import Select from 'components/Select';
import Snackbar from 'components/Snackbar';
import Table from 'components/Table';
import TableBody from 'components/TableBody';
import TableCell from 'components/TableCell';
import TableCellActions from 'components/TableCellActions';
import TableCellHead from 'components/TableCellHead';
import TableHead from 'components/TableHead';
import TableRow from 'components/TableRow';
import TextField from 'components/TextField';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import useIsMobile from 'hooks/useIsMobile';
import useLocationSearch from 'hooks/useLocationSearch';
import useTheme from 'hooks/useTheme';

import actionsSetup from '../actions/setup';
import actionsCostItems from '../actions/costItems';
import pagesURLs from 'constants/pagesURLs';
import * as pages from 'constants/pages';

const getClasses = makeStyles<any>()((_, theme: any) => ({
  cell: {
    minWidth: '40%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(3)}px`,
    overflow: 'hidden',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
  },
  dialogContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(1)}px`,
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '20px',
  },
}));

const ICON_SIZE = 20;
const ICON_SIZE_BIG = 24;

const TABLE_COLUMNS = {
  actions: 'ACTIONS',
  active: 'ACTIVE',
  name: 'NAME',
};

const errorTypes = {
  EMPTY_FIELD_TITLE: 'EMPTY_FIELD_TITLE',
};

const CostItems = () => {
  const { theme } = useTheme();
  const { classes } = getClasses(theme);
  const { formatMessage } = useIntl();
  const { lang } = useLocationSearch();
  const dispatch: Dispatch<any> = useDispatch();
  const isMobile = useIsMobile();

  const {
    errorMessage,
    isFailed,
    isFailedCreate,
    isFailedDelete,
    isFailedUpdate,
    isFetching,
    isFetchingCreate,
    isFetchingDelete,
    isFetchingUpdate,
    isFinishedCreate,
    isFinishedDelete,
    isFinishedUpdate,
    list: itemsList,
  } = useSelector(({ costItems: reducerItems }: any) => reducerItems);

  const [state, setState] = useState({
    active: false,
    compactColumnIndex: 1,
    itemId: '',
    itemIdToDelete: '',
    itemName: '',
    showCreateDialog: false,
    showErrorAfterSaveAlert: false,
    showSuccessAfterSaveAlert: false,
    validationErrors: [] as string[],
  });

  const getValidationErrors = () => {
    const errors = [];
    if (!state.itemName.trim()) {
      errors.push(errorTypes.EMPTY_FIELD_TITLE);
    }
    return errors;
  };

  const onCloseCreateUpdateDialog = () => setState({
    ...state,
    itemId: '',
    showCreateDialog: false,
    showErrorAfterSaveAlert: false,
    validationErrors: [],
  });

  const onCloseDeleteDialog = () => setState({
    ...state,
    itemIdToDelete: '',
    showErrorAfterSaveAlert: false,
  });

  const onChangeItemName = (value: string) => {
    setState((prevState: any) => ({
      ...prevState,
      itemName: value,
      validationErrors: prevState.validationErrors
        .filter((err: string) => err !== errorTypes.EMPTY_FIELD_TITLE),
    }));
  };

  const onSaveCostItem = () => {
    const validationErrors = getValidationErrors();
    if (!validationErrors.length) {
      if (state.itemId) {
        dispatch(actionsCostItems.fetchUpdateItem({
          active: state.active,
          itemId: state.itemId,
          name: state.itemName,
        }));
      } else {
        dispatch(actionsCostItems.fetchCreateItem({
          active: state.active,
          name: state.itemName,
        }));
      }
    }
    setState({
      ...state,
      validationErrors,
    });
  };

  const tableRows = useMemo(() => (
    itemsList
      .map((item: any) => ({
        itemId: item.id,
        [TABLE_COLUMNS.name]: {
          value: item.name,
        },
        [TABLE_COLUMNS.active]: {
          label: item.active
            ? formatMessage({ id: 'costItem.active' })
            : formatMessage({ id: 'costItem.notActive' }),
          value: item.active,
        },
      }))

  ), [itemsList, lang]);

  useEffect(() => {
    let {
      showCreateDialog,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedCreate) {
      showCreateDialog = isFailedCreate;
      showSuccessAfterSaveAlert = !isFailedCreate;
    }

    setState({
      ...state,
      showCreateDialog,
      showErrorAfterSaveAlert: isFailedCreate,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingCreate]);

  useEffect(() => {
    let {
      itemId,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedUpdate) {
      showSuccessAfterSaveAlert = !isFailedUpdate;
      itemId = isFailedUpdate ? state.itemId : '';
    }

    setState({
      ...state,
      itemId,
      showErrorAfterSaveAlert: isFailedUpdate,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingUpdate]);

  useEffect(() => {
    let {
      itemId,
      itemIdToDelete,
      showSuccessAfterSaveAlert,
    } = state;

    if (isFinishedDelete) {
      itemId = isFailedDelete ? state.itemId : '';
      itemIdToDelete = isFailedDelete ? state.itemIdToDelete : '';
      showSuccessAfterSaveAlert = !isFailedDelete;
    }

    setState({
      ...state,
      itemId,
      itemIdToDelete,
      showCreateDialog: false,
      showErrorAfterSaveAlert: isFailedDelete,
      showSuccessAfterSaveAlert,
    });
  }, [isFetchingDelete]);

  useEffect(() => {
    dispatch(actionsCostItems.fetchItems());
    return () => {
      dispatch(actionsSetup.resetReducersData());
    };
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs>
        <Breadcrumb
          label={formatMessage({ id: 'settings' })}
          to={{ pathname: `${pagesURLs[pages.settings]}` }}
          variant="link"
        />
        <Breadcrumb
          label={formatMessage({ id: 'costItems' })}
          variant="text"
        />
      </Breadcrumbs>
      {(isFetching || isFailed) && (
        <Loading variant={isFailed ? 'error' : 'loading'}>
          {isFailed && (
            <Typography
              color="secondary"
              variant="subtitle"
            >
              {formatMessage({ id: 'loading.error' })}
            </Typography>
          )}
        </Loading>
      )}
      {!isFetching && !isFailed && (
        <>
          <Card>
            <CardTitle>
              <Typography color="secondary">
                {formatMessage({ id: 'create.subtitle' })}
              </Typography>
              <Button
                onClick={() => setState({
                  ...state,
                  active: true,
                  itemId: '',
                  itemName: '',
                  showCreateDialog: true,
                })}
                variant="primary"
                startIcon={(
                  <IconPlus
                    color="button"
                    size={ICON_SIZE}
                  />
                )}
              >
                <Typography color="inherit">
                  {formatMessage({ id: 'add' })}
                </Typography>
              </Button>
            </CardTitle>
          </Card>
          <Card disablePaddings>
            <Table
              compactColumnIndex={state.compactColumnIndex}
              compact={isMobile}
              fixed
              onChangeCompactColumnIndex={(index: number) => setState({
                ...state,
                compactColumnIndex: index,
              })}
            >
              <TableHead>
                <TableRow>
                  <TableCellHead
                    compactVariant="static"
                  >
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.name}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  <TableCellHead
                    compactVariant="dynamic"
                  >
                    <Typography
                      color="secondary"
                      noWrap
                      variant="caption"
                    >
                      {formatMessage({
                        id: `tableColumn.${TABLE_COLUMNS.active}`,
                      })}
                    </Typography>
                  </TableCellHead>
                  {!isMobile && (
                    <TableCellHead size="compactField">
                      <Typography
                        align="center"
                        color="secondary"
                        noWrap
                        variant="caption"
                      >
                        {formatMessage({
                          id: `tableColumn.${TABLE_COLUMNS.actions}`,
                        })}
                      </Typography>
                    </TableCellHead>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row: any) => (
                  <TableRow
                    hover={!isMobile}
                    onClick={isMobile
                      ? () => setState({
                        ...state,
                        active: row[TABLE_COLUMNS.active].value,
                        itemId: row.itemId,
                        itemName: row[TABLE_COLUMNS.name].value,
                      })
                      : undefined
                    }
                  >
                    <TableCell>
                      <Typography>
                        {row[TABLE_COLUMNS.name].value}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row[TABLE_COLUMNS.active].label}
                      </Typography>
                    </TableCell>
                    {!isMobile && (
                      <TableCellActions>
                        <IconButton
                          disableHoverSpace
                          onClick={() => setState({
                            ...state,
                            active: row[TABLE_COLUMNS.active].value,
                            itemId: row.itemId,
                            itemName: row[TABLE_COLUMNS.name].value,
                          })}
                        >
                          <IconEdit size={ICON_SIZE} />
                        </IconButton>
                        <IconButton
                          disableHoverSpace
                          onClick={() => setState({
                            ...state,
                            itemIdToDelete: row.itemId,
                          })}
                        >
                          <IconDelete size={ICON_SIZE} />
                        </IconButton>
                      </TableCellActions>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </>
      )}

      {/* Create/Update Dialog. Desktop */}
      <Dialog
        onClose={onCloseCreateUpdateDialog}
        open={(state.showCreateDialog || !!state.itemId) && !isMobile}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  {
                    id: errorMessage ? 'error' : 'loading.error',
                  },
                  {
                    error: errorMessage,
                  }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card variant="edit">
          <CardTitle>
            <div>
              <Typography variant="title">
                {formatMessage({
                  id: state.itemId ? 'update.title' : 'create.title',
                })}
              </Typography>
              <Typography color="secondary">
                {formatMessage({
                  id: state.itemId ? 'update.subtitle' : 'create.subtitle',
                })}
              </Typography>
            </div>
            <IconButton onClick={onCloseCreateUpdateDialog}>
              <IconClose size={ICON_SIZE_BIG} />
            </IconButton>
          </CardTitle>
          <div/>
          <CardContent>
            <div className={classes.dialogContent}>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    <Colon>
                      <Mandatory>
                        {formatMessage({ id: 'itemName' })}
                      </Mandatory>
                    </Colon>
                  </Typography>
                </div>
                <TextField
                  AdornmentEnd={
                    state.validationErrors
                      .includes(errorTypes.EMPTY_FIELD_TITLE) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={formatMessage({
                          id: `validationError.${errorTypes.EMPTY_FIELD_TITLE}`,
                        })}
                      >
                        <IconError
                          color={theme.colors.redDark}
                          size={ICON_SIZE}
                        />
                      </Tooltip>
                    )
                  }
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  isError={state.validationErrors
                    .includes(errorTypes.EMPTY_FIELD_TITLE)}
                  onChange={({ target }) => onChangeItemName(target.value)}
                  placeholder={formatMessage({
                    id: 'itemName.placeholder',
                  })}
                  size="small"
                  value={state.itemName}
                  variant="standard"
                />
              </div>
              <div className={classes.row}>
                <div className={classes.cell}>
                  <Typography color="secondary">
                    {formatMessage({ id: 'active' })}
                  </Typography>
                </div>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    active: target.value,
                  })}
                  size="small"
                  value={state.active}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({
                        id: 'costItem.active',
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({
                        id: 'costItem.notActive',
                      })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </CardContent>
          <div/>
          <CardActions>
            <Button
              onClick={onCloseCreateUpdateDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingCreate || isFetchingUpdate}
              onClick={onSaveCostItem}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'save' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* Create/Update Dialog. Mobile */}
      <Dialog
        onClose={onCloseCreateUpdateDialog}
        open={(state.showCreateDialog || !!state.itemId) && isMobile}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  {
                    id: errorMessage ? 'error' : 'loading.error',
                  },
                  {
                    error: errorMessage,
                  }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card variant="edit">
          <CardTitle>
            <Typography variant="title">
              {formatMessage({
                id: state.itemId ? 'update.title' : 'create.title',
              })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseCreateUpdateDialog}
            >
              <IconClose size={ICON_SIZE_BIG} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <div className={classes.dialogContainerMobile}>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  <Colon>
                    <Mandatory>
                      {formatMessage({ id: 'itemName' })}
                    </Mandatory>
                  </Colon>
                </Typography>
                <TextField
                  AdornmentEnd={
                    state.validationErrors
                      .includes(errorTypes.EMPTY_FIELD_TITLE) && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={formatMessage({
                          id: `validationError.${errorTypes.EMPTY_FIELD_TITLE}`,
                        })}
                      >
                        <IconError
                          color={theme.colors.redDark}
                          size={ICON_SIZE}
                        />
                      </Tooltip>
                    )
                  }
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  isError={state.validationErrors
                    .includes(errorTypes.EMPTY_FIELD_TITLE)}
                  onChange={({ target }) => onChangeItemName(target.value)}
                  placeholder={formatMessage({
                    id: 'itemName.placeholder',
                  })}
                  size="small"
                  value={state.itemName}
                  variant="standard"
                />
              </div>
              <div className={classes.dialogContentMobile}>
                <Typography
                  color="secondary"
                  variant="subtitle"
                >
                  {formatMessage({ id: 'active' })}
                </Typography>
                <Select
                  disabled={isFetchingCreate || isFetchingUpdate}
                  fullWidth
                  onChange={({ target }) => setState({
                    ...state,
                    active: target.value,
                  })}
                  size="small"
                  value={state.active}
                >
                  <MenuItem value={true}>
                    <Typography>
                      {formatMessage({
                        id: 'costItem.active',
                      })}
                    </Typography>
                  </MenuItem>
                  <MenuItem value={false}>
                    <Typography>
                      {formatMessage({
                        id: 'costItem.notActive',
                      })}
                    </Typography>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </CardContent>
          <div/>
          <CardActions>
            {isMobile && state.itemId && (
              <IconButton
                disableHoverSpace
                onClick={() => setState({
                  ...state,
                  itemIdToDelete: state.itemId,
                })}
              >
                <IconDelete size={ICON_SIZE} />
              </IconButton>
            )}
            <Button
              onClick={onCloseCreateUpdateDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingCreate || isFetchingUpdate}
              onClick={() => {
                const validationErrors = getValidationErrors();
                if (!validationErrors.length) {
                  if (state.itemId) {
                    dispatch(actionsCostItems.fetchUpdateItem({
                      active: state.active,
                      itemId: state.itemId,
                      name: state.itemName,
                    }));
                  } else {
                    dispatch(actionsCostItems.fetchCreateItem({
                      active: state.active,
                      name: state.itemName,
                    }));
                  }
                }
                setState({
                  ...state,
                  validationErrors,
                });
              }}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'save' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/*Delete Dialog*/}
      <Dialog
        onClose={onCloseDeleteDialog}
        open={!!state.itemIdToDelete}
      >
        {state.showErrorAfterSaveAlert && (
          <Card variant="error">
            <CardTitle>
              <Typography color="error">
                {formatMessage(
                  {
                    id: errorMessage ? 'error' : 'loading.error',
                  },
                  {
                    error: errorMessage,
                  }
                )}
              </Typography>
            </CardTitle>
          </Card>
        )}
        <Card>
          <CardTitle>
            <Typography variant="title">
              {formatMessage({ id: 'delete.title' })}
            </Typography>
            <IconButton
              disableHoverSpace
              onClick={onCloseDeleteDialog}
            >
              <IconClose size={ICON_SIZE_BIG} />
            </IconButton>
          </CardTitle>
          <CardContent>
            <Typography color="secondary">
              {formatMessage({ id: 'delete.subtitle' })}
            </Typography>
          </CardContent>
          <div />
          <CardActions>
            <Button
              onClick={onCloseDeleteDialog}
              variant="secondary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'cancel' })}
              </Typography>
            </Button>
            <Button
              isLoading={isFetchingDelete}
              onClick={() => dispatch(actionsCostItems.fetchDeleteItem({
                itemId: state.itemIdToDelete,
              }))}
              variant="primary"
            >
              <Typography color="inherit">
                {formatMessage({ id: 'delete' })}
              </Typography>
            </Button>
          </CardActions>
        </Card>
      </Dialog>

      {/* ALERTS */}
      <Snackbar
        autoHide
        onClose={() => setState({
          ...state,
          showSuccessAfterSaveAlert: false,
        })}
        open={state.showSuccessAfterSaveAlert}
      >
        <Card variant="success">
          <CardTitle>
            <Typography color="success">
              {formatMessage({ id: 'loading.success' })}
            </Typography>
          </CardTitle>
        </Card>
      </Snackbar>
    </div>
  );
};

export default CostItems;
